import Utils from 'helpers/Utils';
import ACTIONS from 'constants/actionTypes';
import filtersKeys from 'constants/filters';
import { groupPeriodBy } from 'constants/dateFormats';
import ActionReducer from 'types/ActionReducer';
import {
  QuickAdminFiltersType,
  QuickChargebacksFiltersType,
  QuickIconsFiltersType,
  QuickInvoicesFiltersType,
  QuickMyTeamFiltersType,
  QuickPayoutsFiltersType,
  QuickMassPayoutsFiltersType,
  QuickFraudFiltersType,
  QuickBlackWhiteFiltersType,
  QuickRecurringFiltersType,
  QuickReferencesFiltersType,
  QuickReportsFiltersType,
  PaymentsQuickFiltersType,
  OperationsQuickFiltersType,
  QuickRemittanceRecipientAccountFiltersType,
  QuickRemittancePaymentsFiltersType,
  QuickBalanceStatementFiltersType,
  QuickBalanceRealTimeFiltersType,
  QuickCurrencyRatesFiltersType,
  QuickFinancialFileFiltersType,
} from 'types/QuickFilters';

const payments: PaymentsQuickFiltersType = {
  isReady: true,
  [filtersKeys.paymentType]: [],
  [filtersKeys.paymentStatus]: [],
  [filtersKeys.paymentCurrency]: [],
  [filtersKeys.date]: {
    type: '',
    values: [],
  },
  [filtersKeys.amountFrom]: '',
  [filtersKeys.amountTo]: '',
};

const operations: OperationsQuickFiltersType = {
  isReady: true,
  [filtersKeys.operationType]: [],
  [filtersKeys.operationStatus]: [],
  [filtersKeys.paymentCurrency]: [],
  [filtersKeys.date]: {
    type: '',
    values: [],
  },
  [filtersKeys.amountFrom]: '',
  [filtersKeys.amountTo]: '',
};

const payouts: QuickPayoutsFiltersType = {
  isReady: true,
  [filtersKeys.paymentType]: ['payout'],
  [filtersKeys.payoutsType]: [''],
  [filtersKeys.paymentStatus]: [],
  [filtersKeys.date]: {
    type: '',
    values: [],
  },
};
const massPayouts: QuickMassPayoutsFiltersType = {
  isReady: true,

  [filtersKeys.status]: [],
  [filtersKeys.date]: {
    type: '',
    values: [],
  },
};

const reports: QuickReportsFiltersType = {
  isReady: true,
  [filtersKeys.reportId]: '',
  [filtersKeys.reportName]: '',
  [filtersKeys.reportType]: [],
  [filtersKeys.reportStatus]: [],
  [filtersKeys.reportPeriod]: [],
  [filtersKeys.reportRegularity]: '',
  [filtersKeys.reportCreatedAt]: {
    type: '',
    values: [],
  },
};

const teamUsers: QuickMyTeamFiltersType = {
  isReady: true,
  [filtersKeys.role]: [],
  [filtersKeys.status]: [],
  [filtersKeys.userName]: '',
  [filtersKeys.ip]: '',
  [filtersKeys.teamDate]: {
    type: '',
    values: [],
  },
};

const teamActivities: QuickMyTeamFiltersType = {
  isReady: true,
  [filtersKeys.role]: [],
  [filtersKeys.status]: [],
  [filtersKeys.userName]: '',
  [filtersKeys.ip]: '',
  [filtersKeys.teamDate]: {
    type: '',
    values: [],
  },
};

const users: QuickAdminFiltersType = {
  isReady: true,
  [filtersKeys.userId]: '',
  [filtersKeys.email]: '',
  [filtersKeys.ip]: '',
  [filtersKeys.role]: [],
  [filtersKeys.status]: [],
  [filtersKeys.wlId]: [],
  [filtersKeys.teamDate]: {
    type: '',
    values: [],
  },
};

const subscriptionPayments: QuickRecurringFiltersType = {
  isReady: true,
  [filtersKeys.recurringStatus]: [],
  [filtersKeys.recurringCurrency]: [],
  [filtersKeys.recurringAmountFrom]: '',
  [filtersKeys.recurringCreatedAt]: {
    type: '',
    values: [],
  },
};

const invoices: QuickInvoicesFiltersType = {
  isReady: true,
  [filtersKeys.invoicePaymentId]: '',
  [filtersKeys.invoiceProjectId]: '',
  [filtersKeys.invoiceCurrency]: [],
  [filtersKeys.invoiceStatus]: [],
  [filtersKeys.invoiceCreatedAt]: {
    type: '',
    values: [],
  },
};

const paymentOperationReference: QuickReferencesFiltersType = {
  isReady: true,
  [filtersKeys.referencePaymentId]: '',
  [filtersKeys.referenceOperationId]: '',
  [filtersKeys.referenceAmount]: '',
  [filtersKeys.referenceOperationDate]: {
    type: '',
    values: [],
  },
  [filtersKeys.referenceConfirmDate]: {
    type: '',
    values: [],
  },
};

const rcsFraud: QuickFraudFiltersType = {
  isReady: true,
  [filtersKeys.riskMerchantId]: '',
  [filtersKeys.riskProjectIds]: [],
  [filtersKeys.riskContentType]: '',
  [filtersKeys.riskListType]: [],
  [filtersKeys.riskValue]: [],
  [filtersKeys.risksCreatedAt]: {
    type: '',
    values: [],
  },
};

const rcsBlackWhite: QuickBlackWhiteFiltersType = {
  isReady: false,
  [filtersKeys.riskMerchantId]: '',
  [filtersKeys.riskProjectIds]: [],
  [filtersKeys.riskContentType]: '',
  [filtersKeys.riskListType]: [],
  [filtersKeys.riskValue]: [],
  [filtersKeys.risksCreatedAt]: {
    type: '',
    values: [],
  },
};

const chargebacks: QuickChargebacksFiltersType = {
  isReady: true,
  [filtersKeys.chargebacksProjectIds]: [],
  [filtersKeys.chargebacksStatuses]: [],
  [filtersKeys.chargebacksArn]: '',
  [filtersKeys.chargebacksId]: '',
  [filtersKeys.caseId]: '',
  [filtersKeys.chargebacksDate]: {
    type: '',
    values: [],
  },
};

const apsIcon: QuickIconsFiltersType = {
  isReady: false,
  [filtersKeys.iconPaymentMethodId]: '',
  [filtersKeys.iconPaymentMethodName]: '',
};

const remittanceRecipientAccounts: QuickRemittanceRecipientAccountFiltersType =
  {
    isReady: false,
    [filtersKeys.remittanceStatus]: [],
    [filtersKeys.transferCurrency]: [],
    [filtersKeys.remittanceCreationDate]: {
      type: '',
      values: [],
    },
  };

const remittancePayments: QuickRemittancePaymentsFiltersType = {
  isReady: false,
  [filtersKeys.remittancePaymentStatus]: [],
  [filtersKeys.remittancePaymentCurrency]: [],
  [filtersKeys.remittanceCreationDate]: {
    type: '',
    values: [],
  },
};
const balanceStatement: QuickBalanceStatementFiltersType = {
  isReady: true,
  [filtersKeys.balanceStatementBalanceType]: '',
  [filtersKeys.balanceStatementBalanceId]: [],
  [filtersKeys.balanceStatementCompanyId]: '',
  [filtersKeys.balanceStatementContractId]: [],
  [filtersKeys.balanceStatementCurrencyId]: [],
  [filtersKeys.balanceStatementPeriod]: [],
  [filtersKeys.balanceStatementPeriodPreset]: '',
  [filtersKeys.balanceStatementGroupPeriodBy]: groupPeriodBy.Day,
};

const balanceRealTime: QuickBalanceRealTimeFiltersType = {
  isReady: true,
  [filtersKeys.balanceRealTimeBalanceType]: [],
  [filtersKeys.balanceRealTimeCompanyId]: '',
};

const currencyRates: QuickCurrencyRatesFiltersType = {
  isReady: true,
  [filtersKeys.currencyRatesActualDate]: '',
  [filtersKeys.currencyRatesCurrency]: '',
  [filtersKeys.currencyRatesMerchantId]: '',
};

const financialFile: QuickFinancialFileFiltersType = {
  isReady: true,
  [filtersKeys.fileName]: '',
};

const initialState = {
  payments,
  operations,
  payouts,
  massPayouts,
  teamUsers,
  teamActivities,
  users,
  reports,
  subscriptionPayments,
  invoices,
  rcsFraud,
  rcsBlackWhite,
  paymentOperationReference,
  chargebacks,
  apsIcon,
  remittanceRecipientAccounts,
  remittancePayments,
  balanceStatement,
  balanceRealTime,
  currencyRates,
  financialFile,
};

const quickFilters = (state = initialState, action: ActionReducer) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.CHANGE_QUICK_FILTER: {
      if (!Utils.hasProp(state[payload.name], payload.key)) {
        console.log('payload', payload);
        console.warn(`${payload.key} not found in quickFilters reducer`);
        return state;
      }

      const { name, key, value } = payload;
      const newState = {
        ...state,
        [name]: {
          ...state[name],
          [key]: value,
        },
      };

      // Синхронизируем значения amount, т.к в быстрых фильтрах поиск осущеставляется
      // по точному совпадению, поэтому to и from будут иметь одно значение.
      if (key === filtersKeys.amountFrom) {
        newState[name][filtersKeys.amountTo] =
          newState[name][filtersKeys.amountFrom];
      } else if (key === filtersKeys.recurringAmountFrom) {
        newState[name][filtersKeys.recurringAmountTo] =
          newState[name][filtersKeys.recurringAmountFrom];
      }

      return newState;
    }
    case ACTIONS.INIT_QUICK_FILTERS:
      return {
        ...state,
        [payload.name]: { ...payload.content },
      };
    case ACTIONS.RESET_CONCRETE_QUICK_FILTERS:
      return { ...state, [payload.name]: initialState[payload.name] };
    case ACTIONS.RESET_CONCRETE_QUICK_FILTERS_PROPS: {
      const { name, keys } = payload;
      const values = state[payload.name];

      keys.forEach((key) => {
        values[key] = initialState[name][key];
      });

      return { ...state, [name]: values };
    }
    case ACTIONS.RESET_PAYOUT_QUICK_FILTERS:
      return {
        ...state,
        payouts: {
          ...initialState.payouts,
          payoutsType: payload.payoutsType,
        },
      };
    case ACTIONS.RESET_QUICK_FILTERS:
    case ACTIONS.RESET_ALL:
      return initialState;
    default:
      return state;
  }
};

export default quickFilters;
