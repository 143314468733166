import { IFormDescriptorItem } from 'types/Analytics';
import {
  notEmpty,
  shouldContainCurrency,
} from '../../services/ServiceValidation';

const InOutTableGroupByParams: Array<IFormDescriptorItem> = [
  {
    id: 'chartName',
    label: 'analytics.editForm.params.tableTitle.label',
    placeholder: 'analytics.editForm.params.tableTitle.placeholder',
    type: 'input',
    // isRequired: true,
  },
  {
    id: 'chartData',
    type: 'singleSelect',
    label: 'analytics.editForm.params.tableData.label',
    options: [
      {
        value: 'amount',
        label: 'analytics.editForm.params.chartData.channelAmount.item',
      },
    ],
  },
  {
    id: 'groupByRows',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupByRows.label',
    options: [],
    conditionalOptions: [
      {
        data: [
          {
            id: 'projectId',
            text: 'analytics.editForm.params.groupBy.project.item',
            order: 1,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('projectId') === false,
      },
      {
        data: [
          {
            id: 'paymentMethod',
            text: 'analytics.editForm.params.groupBy.paymentMethodType.item',
            order: 2,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('paymentMethod') === false,
      },
      {
        data: [
          {
            id: 'currency',
            text: 'analytics.editForm.params.groupBy.channelCurrency.item',
            order: 3,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('currency') === false,
      },
      {
        data: [
          {
            id: 'type',
            text: 'analytics.editForm.params.groupBy.inOutType.item',
            order: 4,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('type') === false,
      },
      {
        data: [
          {
            id: 'date',
            text: 'analytics.editForm.params.groupBy.operationCompletedAt.item',
            order: 5,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('date') === false,
      },
      {
        data: [
          {
            id: 'paymentMethodId',
            text: 'analytics.editForm.params.groupBy.paymentMethod.item',
            order: 6,
          },
        ],
        condition: (values) =>
          'groupByColumns' in values &&
          values.groupByColumns.includes('paymentMethodId') === false,
      },
    ],
  },
  {
    id: 'groupByColumns',
    type: 'multiSelect',
    label: 'analytics.editForm.params.groupByColumns.label',
    options: [],
    optionsTranslate: true,
    conditionalOptions: [
      {
        data: [
          {
            id: 'projectId',
            text: 'analytics.editForm.params.groupBy.project.item',
            order: 1,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('projectId') === false,
      },
      {
        data: [
          {
            id: 'paymentMethod',
            text: 'analytics.editForm.params.groupBy.paymentMethodType.item',
            order: 2,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('paymentMethod') === false,
      },
      {
        data: [
          {
            id: 'currency',
            text: 'analytics.editForm.params.groupBy.channelCurrency.item',
            order: 3,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('currency') === false,
      },
      {
        data: [
          {
            id: 'type',
            text: 'analytics.editForm.params.groupBy.inOutType.item',
            order: 4,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('type') === false,
      },
      {
        data: [
          {
            id: 'date',
            text: 'analytics.editForm.params.groupBy.operationCompletedAt.item',
            order: 5,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('date') === false,
      },
      {
        data: [
          {
            id: 'paymentMethodId',
            text: 'analytics.editForm.params.groupBy.paymentMethod.item',
            order: 6,
          },
        ],
        condition: (values) =>
          'groupByRows' in values &&
          values.groupByRows.includes('paymentMethodId') === false,
      },
    ],
  },
  {
    id: 'groupByDate',
    type: 'singleSelect',
    label: 'analytics.editForm.params.groupPeriodBy.label',
    options: [
      {
        value: '1d',
        label: 'analytics.editForm.params.groupPeriodBy.day.item',
      },
      {
        value: '1w',
        label: 'analytics.editForm.params.groupPeriodBy.week.item',
      },
      {
        value: '1m',
        label: 'analytics.editForm.params.groupPeriodBy.month.item',
      },
      {
        value: '3m',
        label: 'analytics.editForm.params.groupPeriodBy.quarter.item',
      },
    ],
    renderOptions: {
      hidden: {
        condition: (values) =>
          'groupByRows' in values &&
          'groupByColumns' in values &&
          [...values.groupByRows, ...values.groupByColumns].includes('date') ===
            false,
      },
    },
  },
  {
    id: 'period',
    type: 'date',
    label: 'analytics.editForm.params.period.label',
  },
];

const InOutTableFilters: Array<IFormDescriptorItem> = [
  {
    id: 'projects',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.project.label',
    dictionaryKey: 'projects',
  },
  {
    id: 'paymentMethod',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentMethodType.label',
    dictionaryKey: 'paymentMethod',
  },
  {
    id: 'currency',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.channelCurrency.label',
    dictionaryKey: 'currency',
    tooltip: 'select.currency.favorite.tooltip',
  },
  {
    id: 'type',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.inOutType.label',
    options: [
      {
        id: 'payin',
        text: 'Pay-In',
      },
      {
        id: 'payout',
        text: 'Pay-Out',
      },
      {
        id: 'refund',
        text: 'Refund',
      },
    ],
  },
  {
    id: 'country',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.countryByBIN.label',
    dictionaryKey: 'country',
  },
  {
    id: 'paymentMethodId',
    type: 'multiSelect',
    label: 'analytics.editForm.filters.paymentMethod.label',
    dictionaryKey: 'paymentMethodFromDictionary',
  },
];

const FormValidate = [
  {
    fields: ['groupByRows', 'groupByColumns'],
    errId: 'onlyWithCurrency',
    validator: shouldContainCurrency,
  },
];

const InOutFieldValidations = {
  chartName: [notEmpty],
};

export {
  InOutTableGroupByParams,
  InOutTableFilters,
  FormValidate,
  InOutFieldValidations,
};
