import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { ThunkDispatch } from 'redux-thunk';
import { addEntityToSaved } from 'actions/savedEntities';
import { WithRouterProps } from 'decorators/withRouter';
import OperationsPage from './OperationsPage';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import fetchUpdateDictionary from 'helpers/dictionaries';
import { AnyObject } from 'types/Common';
import landingState from 'constants/landingState';
import { RootState, StoreProps } from 'store';
import ItemConfiguration from 'types/ItemConfiguration';
import Messages from 'constants/rpcTypes';
import { getPathUrl } from 'helpers/paymentsPayoutsRequest';
import path from 'helpers/path';
import getConfigurationByName from 'selectors/getConfigurationByName';
import tableNames from 'constants/tableNames';
import permissionReasons from 'constants/permissionReasons';
import savedEntities from 'constants/savedEntities';
import pageFeatures from 'constants/pageFeatures';
import ActionReducer from 'types/ActionReducer';
import { SortingType } from '../../types/sortings';

interface ConnectedProps {
  configuration: ItemConfiguration[];
  feature_configuration: ItemConfiguration[];
  sort: {
    field: string;
    order: SortingType;
  };
  tableData: {
    items: AnyObject[];
    isFetch: boolean;
  };
  isDemoUser: boolean;
}

type Props = ConnectedProps &
  StoreProps &
  WithPermissions &
  WithRouterProps<{ id?: string }>;

class OperationsContainer extends Component<Props> {
  loadPaymentMethodGroup;
  constructor(props: Props) {
    super(props);
    this.loadPaymentMethodGroup = fetchUpdateDictionary.bind(
      this,
      'paymentMethodGroup'
    );
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    // TODO: maybe we dont need it
    // because we dont depend on payments to show methods now!
    // in some cases when new payments are being added, we may not have their paymentMethodType (DAS-6665)
    const { tableData } = this.props;
    if (
      !tableData.isFetch &&
      tableData.items.length !== prevProps.tableData.items.length
    ) {
      this.loadPaymentMethodGroup();
    }
  }

  render() {
    const {
      match: { params },
      configuration,
      feature_configuration,
      isDemoUser,
    } = this.props;

    if (!configuration) return null;

    return (
      <OperationsPage
        id={params.id}
        isDemoUser={isDemoUser}
        configuration={configuration}
        feature_configuration={feature_configuration}
        sort={this.props.sort}
        goToOperation={this.goToOperation}
        openTabWithPayment={this.createTabWithOperation}
        isRefundEnabled={false}
      />
    );
  }

  goToOperation = (data) => {
    if (this.props.isEnabled(Messages.Operations_Operation)) {
      const pathUrl = getPathUrl(tableNames.operations, data);
      if (pathUrl) {
        this.props.history.push(path(pathUrl));
      }
    }
  };

  createTabWithOperation = (data) => {
    const { operation_id } = data;
    const dispatch: ThunkDispatch<RootState, void, ActionReducer> =
      this.props.dispatch;

    const regex: RegExp = /\?.+/;

    const str: string = getPathUrl(tableNames.operations, data);

    const m: string = regex.exec(str)![0];

    const entityKey: 'operations' = savedEntities.operations;
    const { name, type, projectId } = queryString.parse(m);
    dispatch(
        // revise params !!! TODO:!
      addEntityToSaved({ entityKey, id: operation_id, urlParams: { name, type, projectId } })
    );
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  configuration: getConfigurationByName(state, tableNames.operations),
  feature_configuration: getConfigurationByName(
    state,
    pageFeatures[tableNames.operations] || null
  ),
  sort: state.data[tableNames.operations].sort,
  tableData: state.data.operations,
  isDemoUser: state.user.landingState === landingState.demoStage,
});

export default withRouter(
  connect(mapStateToProps)(addPermissions(OperationsContainer))
);
