export const paymentHotCurrency = [
  {
    id: 'EUR',
    text: 'EUR',
  },
  {
    id: 'USD',
    text: 'USD',
  },
];
