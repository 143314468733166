import React, { Component } from 'react';
import { connect } from 'react-redux';

import { updateField } from 'actions/templateBuilder';
import BuilderColumns from './BuilderColumns';
import { AnyObject } from 'types/Common';
import { getReportColumns } from 'api/reports';
import getSelectionListItems from 'creators/getSelectionListItems';
import { isEqual } from 'lodash';
import reportTypes from 'pages/reports/templatesBuilder/reportTypes';
import { StoreProps } from 'store';

interface ConnectedProps {
  enabledProjects: AnyObject[];
  fields: AnyObject;
  validationErrors: AnyObject;
  isTemplateFetching: boolean;
}

type Props = ConnectedProps & StoreProps;

interface State {
  isLoading: boolean;
}

class BuilderColumnsContainer extends Component<Props, State> {
  private prevColumns;
  private initialSelectedColumns;
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.initialSelectedColumns = props.fields.selectedColumns;
  }

  componentDidMount() {
    this.updateColumns();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.isTemplateFetching) return;

    const isProjectsUpdate = !isEqual(
      prevProps.fields.projects,
      this.props.fields.projects
    );

    if (
      prevProps.fields.reportType !== this.props.fields.reportType ||
      isProjectsUpdate ||
      (prevProps.isTemplateFetching && !this.props.isTemplateFetching)
    ) {
      this.updateColumns(
        prevProps.fields.reportType !== this.props.fields.reportType
      );
    }

    if (prevProps.isTemplateFetching && !this.props.isTemplateFetching) {
      this.initialSelectedColumns = this.props.fields.selectedColumns?.length
        ? this.props.fields.selectedColumns
        : undefined;
    }
  }

  render() {
    const { fields, validationErrors } = this.props;
    const { isLoading } = this.state;

    return (
      <BuilderColumns
        isLoading={isLoading}
        fields={fields}
        onChangeList={this.changeList}
        onMoveColumn={this.moveColumn}
        validationErrors={validationErrors}
        onDeleteColumn={this.deleteColumn}
      />
    );
  }

  changeList = (items: any[]) => {
    const { fields, dispatch } = this.props;
    const newColumns: any[] = [];
    items.forEach((item) => {
      if (item.isSelected) {
        const original = fields.reportColumns.find(
          (field) => field.id === item.id
        );
        if (original) {
          newColumns.push({
            id: original.id,
            text: original.text,
          });
        }
      }
    });

    dispatch(updateField('selectedColumns', newColumns));

    dispatch(updateField('reportColumns', items));
  };

  moveColumn = (newColumns) => {
    const { dispatch } = this.props;
    dispatch(updateField('selectedColumns', newColumns));
  };

  deleteColumn = (id) => {
    const { fields, dispatch } = this.props;

    const newColumns = fields.selectedColumns.filter((column) => {
      return column.id !== id;
    });
    dispatch(updateField('selectedColumns', newColumns));

    if (fields.reportColumns) {
      const newList = fields.reportColumns.map((item) => {
        const copyItem = { ...item };
        if (item.id === id) {
          copyItem.isSelected = false;
        }
        return copyItem;
      });
      dispatch(updateField('reportColumns', newList));
    }
  };

  updateColumns = async (isClearCols = false) => {
    const { fields, enabledProjects, dispatch } = this.props;

    this.setState({ isLoading: true });
    let projectIds: string[];

    
    /*if (fields.providers.some((item) => item.isSelected)) {
      projectIds = fields.projects.reduce(
        (acc, item) => (item.isSelected ? [...acc, item.id] : acc),
        []
      );
    } else {
      projectIds = enabledProjects.map((item) => item.projectId);
    }*/
    
    projectIds = [];

    // DETERMINE WHY WE NEED PROJECT IDS HERE TODO:!!!!
    // WE NEED Project ids to determine if columns are sensetive
    try {
      const columns = await getReportColumns({
        projectIds,
        reportType: fields.reportType,
      });

      let selectedColumns =
        !isClearCols || isEqual(this.prevColumns, columns)
          ? this.initialSelectedColumns || fields.selectedColumns
          : [];

      if (fields.reportType === reportTypes.interchange) {
        selectedColumns = columns;
      }

      this.prevColumns = columns;

      const selectColumns = getSelectionListItems({
        originalList: columns,
        selectedList: selectedColumns,
        getText: ({ text }) => text,
        isReadyOnly: fields.reportType === reportTypes.interchange,
      });

      dispatch(updateField('reportColumns', selectColumns));
      this.setSelectedColumns();
    } catch (e) {
      console.error('Error occurred while getting columns', e);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  setSelectedColumns = () => {
    const { fields, dispatch } = this.props;
    let selectedColumns;
    if (fields.reportType === reportTypes.interchange) {
      selectedColumns = fields.reportColumns.map((col) => {
        return {
          id: col.id,
          text: col.text,
        };
      });
    } else {
      selectedColumns = this.initialSelectedColumns
        .map((selectedColumn) => {
          const dictionaryColumn = fields.reportColumns.find((col) => {
            return (
              col.isSelected &&
              (selectedColumn.id === col.id || selectedColumn === col.id)
            );
          });

          if (!dictionaryColumn) return null;

          return {
            id: dictionaryColumn.id,
            text: dictionaryColumn.text,
          };
        })
        .filter((col) => !!col);
    }

    dispatch(updateField('selectedColumns', selectedColumns));
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  enabledProjects: state.user.enabledProjects,
  fields: state.templateBuilder.fields,
  validationErrors: state.templateBuilder.validationErrors,
  isTemplateFetching: state.templateBuilder.isTemplateFetching,
});

export default connect(mapStateToProps)(BuilderColumnsContainer);
