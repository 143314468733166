import { merge, pickBy, isEmpty } from 'lodash';
import getSelectionListItems from 'creators/getSelectionListItems';
import getNameWithId from 'helpers/getNameWithId';
import getIntervals from './getIntervalsHelper';
import Utils from 'helpers/Utils';
import { FilterTypes } from 'constants/FilterTypes';
import { AnyObject } from 'types/Common';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import reportTypes from "./reportTypes";

class BuilderNormalize {
  /**
   *
   * @param data
   * @param props
   */
  static getDataForState({
    data,
    user,
    filtersValues,
    filterSet,
  }: {
    data: AnyObject;
    user: AnyObject;
    filtersValues: AnyObject;
    filterSet: AnyObject;
  }) {
    const { enabledProviders } = user;
    const { reportTemplateIntervalBy } = filtersValues;

    // Data from server
    const availableCsvDelimiter = [';', ','];
    const allFilters = merge(BuilderNormalize.getInitialData(filterSet), data);

    const {
      name,
      reportTemplateId,
      type,
      periodType,
      schedulePlan,
      isTemplate,
      csvDelimiter,
    } = allFilters;

    let {
      template: {
        columns,
        intervalBy,
        filter: { /*project_id: projects, */ provider_id: providers },
      },
    } = allFilters;

    if (!isEmpty(data) && columns.length === 0 && !providers && !intervalBy) {
      columns = data.columns;
      // projects = data.projects;
      providers = data.providers;
      intervalBy = data.tmeplate.intervalBy;
    }

    // Schedule
    let reportScheduleDateType = '';
    const regularPeriod = periodType !== 'single' ? periodType : '';
    const singlePeriod = '';
    if (periodType) {
      reportScheduleDateType = periodType === 'single' ? 'single' : 'regular';
    }

    let singlePeriodRange: { dateFrom: string; dateTo: string } = {
      dateFrom: '',
      dateTo: '',
    };
    if (schedulePlan && schedulePlan.dateFrom && schedulePlan.dateTo) {
      singlePeriodRange = {
        dateFrom: schedulePlan.dateFrom,
        dateTo: schedulePlan.dateTo,
      };
    }
    const regularStartAt = schedulePlan?.processingDate;

    const filters = Object.keys(filterSet).reduce(
      (resultFiltersByType, currentFilter) => {
        const filtersByType = Object.keys(
          filterSet[currentFilter]
        ).reduce<AnyObject>(
          // eslint-disable-next-line complexity
          (resultFilters, filterName) => {
            if (filterName === 'provider_id') {
              return resultFilters;
            }
            const filterList = filterSet[currentFilter];
            switch (filterList[filterName]?.type) {
              case FilterTypes.select: {
                const value =
                  allFilters.template.filter[filterName] ||
                  allFilters[filterName];
                return {
                  ...resultFilters,
                  [filterName]: {
                    value: value
                      ? {
                          value,
                          label: filtersValues[
                            filterList[filterName].dictionaryId
                          ]?.list.find((item) => item.id.toString() === value)
                            .text,
                        }
                      : null,
                    items: getCustomSelectItems({
                      list:
                        filtersValues[filterList[filterName].dictionaryId]
                          ?.list || [],
                    }),
                  },
                };
              }
              case FilterTypes.multiSelect:
                return {
                  ...resultFilters,
                  [filterName]: getSelectionListItems({
                    originalList:
                      filtersValues[filterList[filterName].dictionaryId]
                        ?.list || [],
                    selectedList:
                      allFilters.template.filter[filterName] ||
                      allFilters[filterName] ||
                      [],
                    getText: ({ text }) => text,
                  }),
                };
              case FilterTypes.text:
              case FilterTypes.numbers:
                return {
                  ...resultFilters,
                  [filterName]:
                    allFilters.template.filter[filterName] ||
                    allFilters[filterName] ||
                    '',
                };
              case FilterTypes.amount:
                return {
                  ...resultFilters,
                  [filterName]: {
                    amountFrom: Utils.getNumberWithSpace(
                      allFilters.template.filter.amountFrom ||
                        allFilters.amount?.amountFrom ||
                        ''
                    ),
                    amountTo: Utils.getNumberWithSpace(
                      allFilters.template.filter.amountTo ||
                        allFilters.amount?.amountTo ||
                        ''
                    ),
                  },
                };
              case FilterTypes.buttonsList:
                return {
                  ...resultFilters,
                  [filterName]: filtersValues[
                    filterList[filterName].dictionaryId
                  ]?.list?.map(({ id, text }) => {
                    return {
                      id,
                      text,
                      isSelected: allFilters.template.filter[filterName]?.find(
                        (filterId) => filterId === id
                      ),
                    };
                  }),
                };
              case FilterTypes.multiText:
              case FilterTypes.multiTextNumbers:
                return {
                  ...resultFilters,
                  [filterName]:
                    allFilters.template.filter[filterName] ||
                    allFilters[filterName] ||
                    [],
                };
              default:
                return resultFilters;
            }
          },
          {}
        );
        return {
          ...resultFiltersByType,
          [currentFilter]: filtersByType,
        };
      },
      {}
    );

    return {
      reportId: reportTemplateId,
      reportType: reportTypes.operations, // fixed now
      reportName: name,
      providers: getSelectionListItems({
        originalList: enabledProviders,
        selectedList: providers,
        keyId: 'id',
        getText: ({id, text}) => {
          return getNameWithId(text, id);
        },
      }),
      reportColumns: [],
      selectedColumns: columns.map((column) => ({ id: column })),
      reportScheduleDateType,
      singlePeriod,
      singlePeriodRange,
      regularPeriod,
      regularStartAt,
      intervalBy: getIntervals(
        reportTemplateIntervalBy?.list,
        type,
        intervalBy
      ),
      csvDelimiter: {
        items: availableCsvDelimiter.map((item) => ({
          label: item,
          value: item,
        })),
        value:
          typeof csvDelimiter === 'string' ? csvDelimiter : csvDelimiter.value,
      },
      ...filters,
      saveAsTemplate: isTemplate,
    };
  }

  /**
   *
   * @param data
   * @param filterSet
   */
  static getDataForBackend(data: AnyObject, filterSet: AnyObject) {
    const {
      reportName,
      reportType,
      providers,  
      reportScheduleDateType,
      singlePeriodRange,
      regularPeriod,
      regularStartAt,
      csvDelimiter,
      selectedColumns,
      saveAsTemplate,
    } = data;

    let schedulePlan;
    if (reportScheduleDateType === 'single') {
      schedulePlan = {
        periodType: 'single',
        ...singlePeriodRange,
      };
    } else if (reportScheduleDateType === 'regular') {
      schedulePlan = {
        periodType: regularPeriod,
        processingDate: regularStartAt,
      };
    }
    const filterList = filterSet[reportType];
    const dataByType = data[reportType];
    const filtersForBackend = Object.keys(filterList).reduce<AnyObject>(
      (result, fieldName) => {
        switch (filterList[fieldName].type) {
          case FilterTypes.select:
            return {
              ...result,
              [fieldName]: dataByType[fieldName].value?.value.toString(),
            };
          case FilterTypes.multiSelect:
          case FilterTypes.buttonsList:
            return {
              ...result,
              [fieldName]: BuilderNormalize.getSelectedIds(
                dataByType[fieldName]
              ),
            };
          case FilterTypes.text:
          case FilterTypes.numbers:
          case FilterTypes.multiText:
          case FilterTypes.multiTextNumbers:
            return { ...result, [fieldName]: dataByType[fieldName] };
          case FilterTypes.amount:
            return {
              ...result,
              amountFrom: Utils.getNumberWithoutSpace(
                dataByType.amount.amountFrom
              ),
              amountTo: Utils.getNumberWithoutSpace(dataByType.amount.amountTo),
            };
          default:
            return result;
        }
      },
      {}
    );

    const intervalBy = data.intervalBy.find((interval) => interval.isChecked);

    return {
      name: reportName.trim(),
      type: reportType,
      schedulePlan,
      isTemplate: saveAsTemplate,
      template: {
        columns: selectedColumns.map(({ id }) => id),
        filter: pickBy(
          {
            ...filtersForBackend,
            provider_id: BuilderNormalize.getSelectedIds(providers),
          },
          (val) => val?.length
        ),
        intervalBy: intervalBy?.id,
      },
      csvDelimiter: csvDelimiter.value,
    };
  }

  /**
   *
   * @param list
   */
  static getSelectedIds(list) {
    
    console.log('GET SELECTED IDS');
    console.log(list);
    
    return list.filter(({ isSelected }) => isSelected).map(({ id }) => id);
  }

  /**
   *
   */
  static getInitialData(filterSet): any {
    const initialType = 'operations';
    const filterList = filterSet[initialType];
    const filters = Object.keys(filterList).reduce<AnyObject>(
      (result: AnyObject, name) => {
        switch (filterList[name].type) {
          case FilterTypes.multiSelect:
          case FilterTypes.buttonsList:
          case FilterTypes.multiText:
          case FilterTypes.multiTextNumbers:
            return { ...result, [name]: [] };
          case FilterTypes.amount:
            return { ...result, [name]: { amountFrom: '', amountTo: '' } };
          case FilterTypes.text:
          case FilterTypes.numbers:
            return { ...result, [name]: '' };
          default:
            return result;
        }
      },
      {}
    );
    return {
      name: '',
      type: initialType,
      reportTemplateId: '',
      reportPaymentType: [],
      isTemplate: false,

      periodType: 'single',
      schedulePlan: {
        dateFrom: '',
        dateTo: '',
        processingDate: '',
      },

      reportCountry: [],
      template: {
        columns: [],
        intervalBy: '',
        filter: {
          // project_id: [],
          provider_id: [],
          ...filters,
        },
      },
      csvDelimiter: ';',
    };
  }

  /**
   *
   * @param data
   */
  static getTableFormat(data) {
    const {
      reportTemplateId,
      name,
      periodType,
      createdAt,
      updatedAt,
      schedulePlan,
    } = data;

    return {
      reportTemplateId,
      name,
      periodType,
      createdAt,
      updatedAt,
      scheduledAt: schedulePlan ? schedulePlan.processingDate : '',
      status: 'active',
    };
  }
}

export default BuilderNormalize;
