import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import Input from 'components/ui/input';
import SelectionList from 'components/ui/selectionList';
import CustomSelect from 'components/ui/customSelect';
import TextBlock from 'components/ui/textBlock';
import { AnyObject } from 'types/Common';
import reportTypes from '../../reportTypes';
import './builderGeneral.scss';

interface Props extends IntlProps {
  fields: AnyObject;
  availableReportTypes: AnyObject[];
  validationErrors: AnyObject;
  onChangeField: (key: string, value: any) => void;
}

class BuilderGeneral extends PureComponent<Props> {
  render() {
    const {
      fields,
      availableReportTypes,
      validationErrors,
      onChangeField,
      getTranslate,
    } = this.props;

    return (
      <div className='templates-builder-general card'>
        <div className='card__header'>
          <div className='card__title'>
            {getTranslate('reports.new.general.label')}
          </div>
        </div>
        <div className='card__content card__container'>
          <Input
            isRow={true}
            label='reports.new.general.name.label'
            placeholder={getTranslate('reports.new.general.name.label')}
            id='name'
            value={fields.reportName}
            maxLength={60}
            error={validationErrors.name}
            onChange={({ target }) => onChangeField('reportName', target.value)}
            customClass='templates-builder-general__item'
          />
          <Input
              isRow={true}
              label='reports.new.general.id.label'
              placeholder=''
              id='templates-builder-id'
              disabled={true}
              value={fields.reportId}
              error={validationErrors.reportId}
              onChange={({ target }) => onChangeField('reportId', target.value)}
              customClass='templates-builder-general__item templates-builder-general__item_id'
          />
          <Input
              isRow={true}
              label='reports.new.general.type.label'
              placeholder=''
              id='templates-builder-id'
              disabled={true}
              value={getTranslate(`reports.new.general.type.values.${fields.reportType}`)}
              error={validationErrors.reportType}
              onChange={({ target }) => onChangeField('reportType', target.value)}
              customClass='templates-builder-general__item templates-builder-general__item_id'
          />
          <SelectionList
            id='provider_id'
            isRow={true}
            label='reports.new.general.providers.label'
            items={fields.providers}
            isFullMode={true}
            error={validationErrors.template?.filter?.provider_id}
            onChange={(items) => onChangeField('providers', items)}
            customClass='templates-builder-general__item'
          />
          {fields.reportType === reportTypes.interchange && (
            <div className='templates-builder-general__note utils-ui-block__row grid-row'>
              <TextBlock
                text={getTranslate('reports.new.general.InfoIC.text')}
                theme='bordered'
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  createOption(id) {
    const { getTranslate } = this.props;
    return {
      value: id,
      label: getTranslate(`reports.new.general.type.values.${id}`),
    };
  }
}

export default addTranslation(BuilderGeneral);
