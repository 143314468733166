import React, { PureComponent, Fragment } from 'react';
import { ReactSortable } from 'react-sortablejs';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import SelectionList from 'components/ui/selectionList';
import Chip from 'components/ui/chip';
import reportTypes from '../../reportTypes';
import { AnyObject } from 'types/Common';
import './builderColumns.scss';

interface Props extends IntlProps {
  fields: AnyObject;
  validationErrors: AnyObject;
  onChangeList: (items: any[]) => void;
  onMoveColumn: (columns: any[]) => void;
  onDeleteColumn: (id: string) => void;
  isLoading: boolean;
}

interface State {
  isDragging: boolean;
}

class BuilderColumns extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isDragging: false,
    };
  }

  render() {
    const { fields, isLoading, onChangeList, validationErrors, getTranslate } =
      this.props;

    if (!fields.selectedColumns) return null;

    const isInterchange = fields.reportType === reportTypes.interchange;
   // const isProjectSelected = fields.projects.some((item) => item.isSelected);

    return (
      <div className='templates-builder-columns card'>
        <div className='card__header'>
          <div className='card__title'>
            {getTranslate('reports.new.columns.label')}
          </div>
        </div>
        <div className='card__content card__container'>
          {fields.reportColumns && (
            <SelectionList
              id='columns'
              items={fields.reportColumns}
              onChange={onChangeList}
              customClass='templates-builder-columns__selection-list'
              isSelectAll={!isInterchange}
              withGroups={!isInterchange}
              error={validationErrors.template?.columns}
              icon='search'
              isLoading={isLoading}
              placeholder={'' /*!isProjectSelected
                  ? getTranslate('reports.new.columns.noGeneralInfo.hint')
                  : undefined*/}
                
              isDisabled={isLoading}
              itemsPerRow={2}
            />
          )}
          {this.renderDragArea()}
        </div>
      </div>
    );
  }

  renderDragArea() {
    const { fields, onMoveColumn, onDeleteColumn, isLoading, getTranslate } =
      this.props;
    const { isDragging } = this.state;
    if (!fields.selectedColumns.length) {
      return null;
    }

    const isInterchange = fields.reportType === reportTypes.interchange;

    return (
      <Fragment>
        {!isInterchange && (
          <div className='templates-builder-columns__info'>
            {getTranslate('reports.new.columns.hint')}
          </div>
        )}
        {!isLoading && (
          <div
            className={classNames('templates-builder-columns__drag', {
              'templates-builder-columns__drag_active': isDragging,
            })}>
            <ReactSortable
              key={fields.reportType}
              list={fields.selectedColumns}
              setList={(columns) => onMoveColumn(columns)}
              onStart={this.onStartDrag}
              disabled={isInterchange}
              animation={0}
              onEnd={this.onStopDrag}>
              {fields.selectedColumns.map((column) => {
                return (
                  <Chip
                    key={column.id}
                    id={column.id}
                    text={column.text}
                    isDisabled={isInterchange}
                    onClick={() => onDeleteColumn(column.id)}
                  />
                );
              })}
            </ReactSortable>
          </div>
        )}
      </Fragment>
    );
  }

  onStartDrag = () => {
    this.setState({ isDragging: true });
  };

  onStopDrag = () => {
    this.setState({ isDragging: false });
  };
}

export default addTranslation(BuilderColumns);
