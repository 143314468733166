import React, { PureComponent } from 'react';
import { throttle } from 'lodash';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Animation from 'components/ui/animation';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import NoContent from 'components/ui/noContent';
import Teaser from 'components/ui/teaser';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import PaymentPageUI from 'types/PaymentPageUI';
import { Config } from 'components/paymentCard/PaymentCardContainer';
import CustomScrollbar from 'components/ui/customScrollbar';
import CopyToClipboard from 'components/ui/copyToClipboard';
import OperationInfo from 'components/operationCard/operationInfo';
import PaymentInfo from 'components/paymentCard/paymentInfo';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import breakpoints from 'constants/breakpoints';
import './operationCard.scss';
import SavedOperation from "../../../types/savedEntity/SavedOperation";

interface Props extends IntlProps, WithPermissions {
  operation?: SavedOperation;
  config?: Config;
  projectId?: string;
  isLoading: boolean;
  syncPaymentUI: (id: string, ui: PaymentPageUI) => void;
}

interface State {
  pageUI: PaymentPageUI;
  canRenderActions: boolean;
}

class OperationCard extends PureComponent<Props, State> {
  id: string;
  constructor(props) {
    super(props);
    this.state = {
      pageUI: { ...props.operation?.ui },
      canRenderActions: true,
    };
    this.id = props.operation.id;
    this.handleResize = throttle(this.handleResize, 500);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
  }

  render() {
    const { operation, config, isLoading, getTranslate } = this.props;
    let paymentIdWithCopyToClipboard: string | JSX.Element;
    
    if (operation?.id) {
      paymentIdWithCopyToClipboard = (
        <div className='payment-card__payment-id-with-copy-to-clipboard'>
          <span className='payment-card__payment-id'>
            {operation?.id}
          </span>
          <CopyToClipboard
            text={operation?.id}
            customClass='ui-copy-to-clipboard_color_white'
          />
        </div>
      );
    } else {
      paymentIdWithCopyToClipboard = ``;
    }

    return (
      <PageTemplate.Container customClass='payment-card'>
        <div className='payment-card__inner'>
          <Animation>
            {operation?.data?.error ? (
              <NoContent
                text={getTranslate(
                  'payments.single.paymentInfo.noPaymentDetails.text'
                )}
              />
            ) : (
              <div className='payment-card__wrapper'>
                <div className='payment-card__item'>
                  <Teaser
                    title={getTranslate('payments.single.operationCard.header')}
                    text={paymentIdWithCopyToClipboard}
                    status={operation?.data?.operationStatus}
                    isLoading={isLoading}>
                  </Teaser>
                </div>
                <div className='payment-card__item payment-card__item_operation-info'>
                  {this.renderOperationInfo()}
                </div>
              </div>
            )}
          </Animation>
        </div>
      </PageTemplate.Container>
    );
  }

  componentWillUnmount() {
    const { syncPaymentUI, operation } = this.props;
    const { pageUI } = this.state;
    window.removeEventListener('resize', this.handleResize);

    if (!operation) return;
    syncPaymentUI(this.id, pageUI);
  }

  renderOperationInfo = () => {
    const { operation, isLoading } = this.props;
    const { pageUI } = this.state;

    console.log('page ui!!!');
    console.log(pageUI);
    
    return (
      <div className='payment-card__operation-info card utils-flex-column'>
        <div className='payment-card__content utils-flex-column'>
          {isLoading || operation?.data === undefined ? (
            <Loader type='bung' />
          ) : (
            <>
                <CustomScrollbar
                  customHeight='100%'
                  initialScrollTop={pageUI.operationInfoScrollTop}
                  needResetScrollTop={true}
                  onScrollStop={({ scrollTop }) =>
                    this.changePageUI({ operationInfoScrollTop: scrollTop })
                  }>
                  <div className='card__container card__content card__content__operation-info'>
                    <OperationInfo
                      operation={operation.data}
                      pageUI={pageUI}
                      onChangePageUI={this.changePageUI}
                      modern
                    />
                  </div>
                </CustomScrollbar>
            </>
          )}
        </div>
      </div>
    );
  };
  
  changePageUI = (object) => {
    this.setState((prevState) => ({
      ...prevState,
      pageUI: {
        ...prevState.pageUI,
        ...object,
      },
    }));
  };

  handleResize = () => {
  };
}
export default addTranslation(addPermissions(OperationCard));
