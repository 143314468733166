import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

/**
 * for Advanced Analytics
 */

export const RENAME_PARAM_MAPPING = {
  [AdvancedAnalyticsEntityType.operations]: {
    projectId: 'projects',
  },
  [AdvancedAnalyticsEntityType.payments]: {
    projectId: 'projects',
  },
  [AdvancedAnalyticsEntityType.inout]: {
    projectId: 'projects',
    columns: 'groupByColumns',
    rows: 'groupByRows',
    issuerCountry: 'country',
  },
  [AdvancedAnalyticsEntityType.declineReasons]: {
    columns: 'groupByColumns',
    rows: 'groupByRows',
    issuerCountry: 'countryByBin',
    country: 'countryByIp',
  },
  [AdvancedAnalyticsEntityType.topten]: {},
  [AdvancedAnalyticsEntityType.declineCodes]: {},
  [AdvancedAnalyticsEntityType.fraud_report]: {},
  [AdvancedAnalyticsEntityType.chargeback]: {},
};

export const FILTERS_RENAME_PARAM_MAPPING = {
  [AdvancedAnalyticsEntityType.operations]: {
    projects: 'projectId',
  },
  [AdvancedAnalyticsEntityType.payments]: {
    projects: 'projectId',
  },
  [AdvancedAnalyticsEntityType.inout]: {
    projects: 'projectId',
    groupByColumns: 'columns',
    groupByRows: 'rows',
    country: 'issuerCountry',
  },
  [AdvancedAnalyticsEntityType.declineReasons]: {
    groupByColumns: 'columns',
    groupByRows: 'rows',
    countryByBin: 'issuerCountry',
    countryByIp: 'country',
  },
  [AdvancedAnalyticsEntityType.topten]: {},
  [AdvancedAnalyticsEntityType.declineCodes]: {},
  [AdvancedAnalyticsEntityType.fraud_report]: {},
  [AdvancedAnalyticsEntityType.chargeback]: {},
};
