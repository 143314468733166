import filtersKeys from 'constants/filters';
import { FilterTypes } from 'constants/FilterTypes';
import modes from 'components/ui/buttonsGroup/modes';
import { paymentHotCurrency } from 'components/quickFiltersContainer/defaultValues';

export default [
  {
    filterId: filtersKeys.operationType,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    view: 'separate',
    metric: 'payments.fastFilter.type',
    list: [
      { id: 'sale', text: 'types.sale' },
      { id: 'payout', text: 'types.payout' },
      { id: 'recurring', text: 'types.recurring' },
    ],
  },
  {
    filterId: filtersKeys.operationStatus,
    type: FilterTypes.buttonsList,
    mode: modes.multi,
    view: 'separate',
    metric: 'payments.fastFilter.status',
    list: [
      { id: 'success', text: 'statuses.success' },
      { id: 'decline', text: 'statuses.decline' },
      { id: 'refunded', text: 'statuses.refund' },
    ],
  },
  {
    filterId: filtersKeys.paymentCurrency,
    type: FilterTypes.buttonsList,
    mode: modes.single,
    metric: 'payments.fastFilter.currency',
    dictionary: 'paymentHotCurrency',
    list: paymentHotCurrency,
  },
  {
    filterId: filtersKeys.amountFrom,
    type: FilterTypes.amount,
    title: 'quickFilters.amountPlaceholder',
    metric: 'payments.fastFilter.amount.enter',
  },
  {
    filterId: filtersKeys.date,
    type: FilterTypes.dateButtons,
    metricName: 'payments',
  },
];
