import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import {
  getSavedEntities,
  removeAllEntities,
  removeEntityFromSaved,
} from 'actions/savedEntities';
import SavedEntitiesView from './SavedEntities';
import path from 'helpers/path';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import MetricService from 'helpers/metricService/MetricService';
import { EntitiyNamesMap } from 'helpers/metricService/metricTypes';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import savedEntitiesConst from 'constants/savedEntities';
import Messages from 'constants/rpcTypes';
import urlsMap from 'constants/urlsMap';
import SavedEntity from 'types/savedEntity';
import { StoreProps } from 'store';

interface OwnProps {
  entityKey: keyof typeof savedEntitiesConst;
  customClass?: string;
  isNew?: boolean;
  isFirstTab?: boolean;
}

interface ConnectedProps {
  savedEntities: {
    [key: string]: {
      isFetch: boolean;
      items: SavedEntity[];
    };
  };
  isLoginAsSupport: boolean;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  WithPermissions &
  WithRouterProps<{
    id?: string;
    formId?: string;
  }>;

class SavedEntitiesContainer extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.getEntity();
  }

  render() {
    const {
      savedEntities,
      entityKey,
      match: { params },
      customClass,
      isNew,
      isFirstTab = true,
    } = this.props;

    const currentEntity = savedEntities[entityKey];

    if (!currentEntity || !currentEntity.isFetch) return null;

    return (
      <SavedEntitiesView
        entityKey={entityKey}
        currentId={params.id}
        currentUrl={this.props.match.url}
        isNew={isNew}
        items={currentEntity.items}
        buildUrl={this.buildUrl}
        onRemove={this.removeItem}
        closeAllEntities={this.closeAllEntities}
        customClass={customClass}
        isFirstTab={isFirstTab}
      />
    );
  }

  getEntity = () => {
    const {
      entityKey,
      isEnabled,
      savedEntities: storedSavedEntities,
      dispatch,
    } = this.props;
    let canGetFromStorage: boolean = true;
    switch (entityKey) {
      case savedEntitiesConst.payments:
      case savedEntitiesConst.payouts:
        canGetFromStorage = isEnabled(Messages.Payments_Payment);
        break;
      case savedEntitiesConst.operations:
        canGetFromStorage = isEnabled(Messages.Operations_Operation);
        break;
    }

    if (storedSavedEntities[entityKey]?.isFetch) return;
    dispatch(
      getSavedEntities({
        entityKey,
        canGetFromStorage,
      })
    );
  };

  removeItem = ({ id, isCurrent }: { id: string; isCurrent: boolean }) => {
    const { dispatch, entityKey, savedEntities } = this.props;
    const currentEntity = savedEntities[entityKey];

    const isSupport = currentEntity.items.find((item) => item.id === id)
      ?.hiddenParams.isSupport;
    if (!isSupport && isNotAvailableForSupport(Messages.ClientTab_Update))
      return;

    const removedIndex = currentEntity.items.findIndex(
      (item) => item.id === id
    );

    dispatch(removeEntityFromSaved({ entityKey, id }));

    if (removedIndex > -1 && isCurrent) {
      this.replaceUrl(removedIndex);
    }
  };

  closeAllEntities = () => {
    const { dispatch, entityKey, savedEntities, match, history } = this.props;
    if (isNotAvailableForSupport(Messages.ClientTab_Update)) return;

    const currentIndex = savedEntities[entityKey].items.findIndex(
      (item) => item.id === match.params.id
    );
    if (currentIndex > -1 || match.path.includes('create')) {
      history.push(`/${urlsMap[entityKey] || entityKey}`);
    }
    dispatch(removeAllEntities({ entityKey }));
    MetricService.send({
      action: 'click',
      actionKey: `${
        EntitiyNamesMap[entityKey] || entityKey
      }.registry.closeAllTabs.button`,
    });
  };

  /**
   * При удалении таба из списка меняем url, чтобы переключить на
   * предыдущий таб или дефолтный list, если в списке сохраненного ничего нет.
   * Поведение интерфейса похоже на работу вкладок в браузере.
   * @param removedIndex
   */
  replaceUrl = (removedIndex) => {
    const { history, entityKey, savedEntities } = this.props;
    const currentEntity = savedEntities[entityKey];

    if (
      currentEntity.items.length === 0 ||
      !currentEntity.items[removedIndex - 1]
    ) {
      history.replace(path(`/${urlsMap[entityKey] || entityKey}`));
    } else {
      const item = currentEntity.items[removedIndex - 1];
      history.replace(path(this.buildUrl(item)));
    }
  };

  buildUrl = (item: SavedEntity): string => {
    const { entityKey } = this.props;
    const rootUrl = urlsMap[entityKey] || entityKey;
    if (item.urlParams && Object.keys(item.urlParams).length > 0) {
      const params = queryString.stringify(item.urlParams);
      return `/${rootUrl}/${item.id}?${params}`;
    }
    return `/${rootUrl}/${item.id}`;
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  savedEntities: state.savedEntities,
  isLoginAsSupport: state.user.isLoginAsSupport,
});

export default withRouter(
  connect(mapStateToProps)(addPermissions(SavedEntitiesContainer))
);
