import React, { PureComponent } from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import ProfileInfoContainer from './components/info';
import ProfileSecureContainer from './components/secure';
import ProfileTokenContainer from './components/tokens';
import TgmBotContainer from './components/tgmBot';
import ProfileActivityContainer from './components/activity';
import PageTemplate from 'components/pageTemplate';
import Button from 'components/ui/button';

import './profile.scss';

interface Props extends IntlProps {
  logout: () => void;
}

class ProfilePage extends PureComponent<Props> {
  render() {
    return (
      <PageTemplate.Main topPadding customClass='page-profile'>
        <PageTemplate.Container>
          <div className='page-profile__inner'>
            <ProfileInfoContainer customClass='page-profile__column page-profile__column_info' />
            <div className='page-profile__column page-profile__column_main'>
              <ProfileSecureContainer customClass='page-profile__item' />
              <ProfileActivityContainer customClass='page-profile__item' />
            </div>
            <div className='page-profile__logout'>
              <Button
                icon='im-Log-out'
                iconPosition='left'
                text={this.props.getTranslate('common.logOut.button')}
                size='x-large'
                status='danger-outline'
                onClick={this.props.logout}
                customClass='ui-button_full-width'
              />
            </div>
          </div>
        </PageTemplate.Container>
      </PageTemplate.Main>
    );
  }
}

export default addTranslation(ProfilePage);
