import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Список payments
 * @param params
 */
export const getOperations = (params) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.Operations_List,
          payload: { ...params },
        },
      ],
      isCancelable: true,
    })
    .then((data) => ({ ...data, items: data.operationsList }));
};

export const getMassPayouts = (params) => {
  return new RpcService()
    .request({
      messages: [
        {
          type: RpcTypes.BulkPayouts_List,
          payload: { ...params },
        },
      ],
    })
    .then((data) => ({ ...data, items: data.rows, totalRows: Infinity }));
};

export const generateTestPayments = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperationGenerator_GenerateInit,
      },
    ],
  });
};

export const isTestPaymentsAvailable = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentOperationGenerator_GenerateAvailability,
      },
    ],
  });
};

type SupportRequestAction = {
  actions: {
    id: string;
    title: string;
    type: string;
    issueType?: string;
    customMessage?: string;
  }[];
  messages: string[];
};

export const getSupportTicketRequests = (params: {
  transactionId: string;
  operationId: string;
}): Promise<SupportRequestAction> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentIssue_RequestActions,
        payload: params,
      },
    ],
  });
};

export const sendPaymentIssueReport = (params: {
  actionId: string;
  comment?: string;
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentIssue_PerformAction,
        payload: params,
      },
    ],
  });
};

export const getPaymentSummaryData = (params: any) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.PaymentsSummary_Data,
        payload: params,
      },
    ],
  });
};

/**
 * Получить данные об операции
 * @param string operationId
 */
export const getOperationById = (operationId: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Operations_Operation,
        payload: { operationId },
      },
    ],
  });
};

export default {
  getOperations,
  getOperationById,
};
