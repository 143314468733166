import React, { Component, Fragment } from 'react';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { AnyObject } from 'types/Common';
import { QuickFiltersTableName } from 'types/QuickFilters';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import PageTemplate from 'components/pageTemplate/PageTemplate';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import Switch from 'components/ui/switch';
import { Column, Row } from 'components/ui/table';
import DataListContainer from 'components/dataListContainer/DataListContainer';
import TopPanelContainer from './components/topPanel/TopPanelContainer';
import SwitcherTabs from 'components/ui/switcherTabs';
import { minTeamTableColumnsWidth } from './constants';
import './team.scss';

interface OwnProps {
  tableName: QuickFiltersTableName;
  canRenderTable: boolean;
  isLoading: boolean;
  configuration: AnyObject;
  apiRequest: (data?: any) => Promise<any>;
  onChangeCurrentTable: (tableName) => void;
  onToggleActivate: (data: any) => void;
  onDeleteUser: (id: number) => void;
  onEditUser: (id: number) => void;
}

type Props = OwnProps & IntlProps & WithPermissions;

class TeamPage extends Component<Props> {
  render() {
    const { onChangeCurrentTable, getTranslate } = this.props;
    return (
      <PageTemplate.Main customClass='page-team'>
        <PageTemplate.Entities>
          <SwitcherTabs
            tabs={[
              {
                id: tableNames.teamUsers,
                text: getTranslate('myteam.quickFilters.reporttype.users'),
              },
              /*
              TODO: open when ready task about activities
              {
                id: tableNames.teamActivities,
                text: getTranslate(
                  'myteam.quickFilters.reporttype.users.activities'
                ),
              },*/
            ]}
            sectionName='team'
            activeTab={this.props.tableName}
            onClick={onChangeCurrentTable}
          />
        </PageTemplate.Entities>
        <PageTemplate.Container>{this.renderContent()}</PageTemplate.Container>
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { tableName, isLoading, apiRequest } = this.props;
    const { canRenderTable } = this.props;

    return (
      <Fragment>
        <TopPanelContainer tableName={tableName} quickFilter={tableName} />
        {canRenderTable && (
          <DataListContainer
            minColumnsWidth={minTeamTableColumnsWidth}
            forbidResizeFor={['userPhoto']}
            apiRequest={apiRequest}
            tableName={tableName}
            rowRender={this.rowRender}
            quickFilter={tableName}
            updateOnTimezoneChange={tableName === tableNames.teamActivities}
          />
        )}
        {isLoading && <Loader />}
      </Fragment>
    );
  }

  rowRender = (data) => {
    const {
      configuration,
      onToggleActivate,
      onDeleteUser,
      onEditUser,
      isEnabled,
      getTranslate,
    } = this.props;

    return (
      <Row key={data.id}>
        {configuration.map((config) => {
          const { id } = config;
          let content = data[id];
          if (id === 'activate') {
            content = (
              <Switch
                id={''}
                checked={data['userStatus'] === 'Active'}
                onChange={() => onToggleActivate(data)}
                customClass='page-team__switch'
                disabled={
                  !isEnabled(Messages.Team_EnableUser) ||
                  !isEnabled(Messages.Team_DisableUser)
                }
                tooltip={
                  !isEnabled(Messages.Team_DeleteUser)
                    ? getTranslate('myteam.viewOnly.hint')
                    : undefined
                }
              />
            );
          } else if (id === 'edit') {
            content = (
              <>
                <Button
                  icon='im-Edit1'
                  status='transparent'
                  size='inline'
                  customClass='page-team__edit-btn'
                  onClick={() => onEditUser(data.id)}
                />
                <Button
                  icon='im-Basket'
                  status='transparent'
                  size='inline'
                  customClass='page-team__edit-btn'
                  onClick={() => onDeleteUser(data.id)}
                  disabled={!isEnabled(Messages.Team_DeleteUser)}
                  tooltip={
                    !isEnabled(Messages.Team_DeleteUser)
                      ? getTranslate('myteam.viewOnly.hint')
                      : undefined
                  }
                />
              </>
            );
          }
          return (
            <Column
              key={id}
              id={id}
              columnWidths={data.columnWidths}
              forbidResizeFor={data.forbidResizeFor}
              content={
                content || <span className='ui-table__column-empty'>—</span>
              }
              params={{
                ...config,
                valueId: config.id,
                valueType: config.valueType,
              }}
              customClass={`ui-table__column_${id}`}
            />
          );
        })}
      </Row>
    );
  };
}

export default addTranslation(addPermissions(TeamPage));
