import filters from 'constants/filters';

export default {
  teamUsers: ['email', 'role', 'status', 'username'],
  teamActivities: ['email', 'role', 'status', 'username'],
  balanceStatement: [
    'companyId',
    'contractId',
    'groupPeriodBy',
    'currencyId',
    'balanceId',
    'period',
  ],

  payments: [
    filters.paymentId,
    filters.projectId,
    filters.paymentType,
    filters.paymentMethod,
    filters.paymentStatus,
    filters.paymentCurrency,
    filters.amountFrom,
    filters.amountTo,
    filters.date,
    filters.updatedDate,
    'customerEmail',
    'customerId',
    'paymentAccount',
    'countryByBin',
    'secure3d',
    'fiscalId',
    'companyName',
    'fraud',
    'installmentCount',
  ],
};
