import React, { Component } from 'react';
import { connect } from 'react-redux';
import { unescape, isEqual } from 'lodash';

import api from 'api/user';
import { updateUser } from 'actions/user';
import { addListeners } from 'decorators/addListeners';
import Messages from 'constants/rpcTypes';
import Utils from 'helpers/Utils';
import getNameWithId from 'helpers/getNameWithId';
import MetricService from 'helpers/metricService/MetricService';
import checkFilters from 'helpers/checkFilters';
import getSelectionListItems from 'creators/getSelectionListItems';
import ProfileInfo from './ProfileInfo';
import TimezoneItem from 'types/TimezoneItem';
import FieldsType from './FieldsType';
import { AnyObject } from 'types/Common';
import { StoreProps } from 'store';
import getCustomSelectItems from 'creators/getCustomSelectItems';
import { scrollToError } from 'components/formFields/helpers';

interface ConnectedProps {
  user: any;
  timezones: {
    isFetch: boolean;
    list: TimezoneItem[];
  };
  contactLanguages: AnyObject[];
  interfaceLanguages: AnyObject[];
  currency: AnyObject[];
}

interface OwnProps extends StoreProps {
  customClass: string;
}

type Props = ConnectedProps & StoreProps & OwnProps;

interface State {
  isEditable: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isUploadingAvatar: boolean;
  fields: FieldsType | null;
  validationErrors: {
    [key: string]: string | AnyObject;
  };
}

@addListeners([Messages.Profile_Update])
class ProfileInfoContainer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isEditable: false,
      isSaving: false,
      isUploadingAvatar: false,
      fields: this.getUserFieldsForState(),
      validationErrors: {},
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      await checkFilters('profile');
    } finally {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps): void {
    const { user } = this.props;
    if (
      user &&
      (!isEqual(user, prevProps.user) ||
        !isEqual(prevProps.currency, this.props.currency))
    ) {
      this.setState({
        fields: this.getUserFieldsForState(),
      });
    }
  }

  render() {
    const { user, customClass } = this.props;
    const {
      fields,
      validationErrors,
      isEditable,
      isSaving,
      isUploadingAvatar,
      isLoading,
    } = this.state;

    if (!fields) return null;

    return (
      <ProfileInfo
        user={user}
        isLoading={isLoading}
        fields={fields}
        validationErrors={validationErrors}
        isEditable={isEditable}
        isSaving={isSaving}
        isChargebackMailingAvailable={user.isChargebackMailingAvailable}
        isFinancialReportMailingAvailable={
          user.isFinancialReportMailingAvailable
        }
        isFraudMailingAvailable={user.isFraudMailingAvailable}
        isUploadingAvatar={isUploadingAvatar}
        onChangeEditable={this.changeEditable}
        onCancelEdit={this.cancelEdit}
        onChangeField={this.changeField}
        onSave={() => this.save()}
        onChangeAvatar={(event) => this.uploadAvatar(event)}
        customClass={customClass}
      />
    );
  }

  changeField = (key: string, value: any, option?) => {
    const { fields } = this.state;

    if (!fields) return;

    const newFields = {
      ...fields,
      [key]: value,
    };

    if (key === 'contactPhone') {
      newFields.contactPhoneCountry = option;
    }

    this.setState({
      fields: newFields,
    });
  };

  changeEditable = (isEditable: boolean) => {
    this.setState({
      isEditable,
    });
  };

  cancelEdit = () => {
    this.changeEditable(false);
    this.setState({
      fields: this.getUserFieldsForState(),
      validationErrors: {},
    });
  };

  async uploadAvatar(event) {
    const { dispatch } = this.props;
    const file = event.target.files[0];

    if (file) {
      try {
        this.setState({ isUploadingAvatar: true });
        const { avaUrl } = await api.uploadAvatar(file);
        dispatch(updateUser({ avaUrl: `${avaUrl}?${Utils.getHash()}` }));
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({ isUploadingAvatar: false });
      }
    }
  }

  async save() {
    const { fields }: any = this.state;

    try {
      this.setState({ isSaving: true });
      await api.updateUser({
        name: fields.name,
        email: fields.email,
        social: fields.social,
        contactPhone: fields.contactPhone
          ? Utils.getNumberWithoutSpace(fields.contactPhone)
          : '',
        contactPhoneCountry: fields.contactPhoneCountry,
        interfaceLang: fields.interfaceLang.value,
        contactLang: fields.contactLang.value,
        timezone: fields.timezone.value,
        showAllCascadingSteps: Number(fields.showAllCascadingSteps),
        isChargebackMailingEnabled: fields.isChargebackMailingEnabled,
        isFinancialReportMailingEnabled: fields.isFinancialReportMailingEnabled,
        isFraudMailingEnabled: fields.isFraudMailingEnabled,
        favoriteCurrencies: fields.favoriteCurrencies
          .filter(({ isSelected }) => isSelected)
          .map(({ id }) => id),
        isPaymentIdGeneratorEnabled: fields.isPaymentIdGeneratorEnabled,
      });
      this.sendMetrics();
      this.changeEditable(false);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isSaving: false });
      scrollToError(this.state.validationErrors, 'bottom');
    }
  }

  sendMetrics() {
    const fields = this.state.fields as FieldsType;
    const selectedFilterCurrencies = fields.favoriteCurrencies
      .filter(({ isSelected }) => isSelected)
      .map(({ id }) => id);

    if (selectedFilterCurrencies?.length) {
      MetricService.send({
        actionKey: 'userProfile.favCcy.save',
        action: 'click',
      });
    }
    MetricService.send({
      actionKey: 'userProfile.edit.save',
      action: 'click',
    });
  }

  getProjectName = (project: string): string => {
    return unescape(project.replace(/&#039;/g, "'"));
  };

  getTimezoneName = (timezone: { name: string; offset: string }): string => {
    return (
      timezone.name.replace('_', ' ').replace('/', ' | ') +
      ' — ' +
      timezone.offset
    );
  };

  getUserTimezone = () => {
    const { user } = this.props;
    return {
      value: user.timezone,
      label: this.getTimezoneName({
        name: user.timezone,
        offset: user.timezoneOffset,
      }),
    };
  };

  getLanguageByAlias = (alias: string, isInterface = false) => {
    const { contactLanguages, interfaceLanguages } = this.props;
    const sourceDictionary = isInterface
      ? interfaceLanguages
      : contactLanguages;
    return (
      (sourceDictionary && sourceDictionary.find(({ id }) => id === alias)) || {
        value: 'en',
        label: 'English',
      }
    );
  };

  getUserFieldsForState(): FieldsType {
    const { user, timezones, currency, contactLanguages, interfaceLanguages } =
      this.props;

    return {
      name: user.name,
      email: user.email,
      social: user.profile.social,
      contactPhone: user.profile.contactPhone,
      contactPhoneCountry: user.profile.contactPhoneCountry,
      isChargebackMailingAvailable: user.isChargebackMailingAvailable,
      isChargebackMailingEnabled: user.isChargebackMailingEnabled,
      isFinancialReportMailingAvailable: user.isFinancialReportMailingAvailable,
      isFinancialReportMailingEnabled: user.isFinancialReportMailingEnabled,
      isFraudMailingAvailable: user.isFraudMailingAvailable,
      isFraudMailingEnabled: user.isFraudMailingEnabled,
      interfaceLang: {
        value: user.interfaceLang,
        label: this.getLanguageByAlias(user.interfaceLang, true).text,
      },
      contactLang: {
        value: user.contactLang,
        label: this.getLanguageByAlias(user.contactLang).text,
      },
      timezone: {
        value: user.timezone,
        label: this.getTimezoneName({
          name: user.timezone,
          offset: user.timezoneOffset,
        }),
      },
      showAllCascadingSteps: Utils.hasProp(user, 'showAllCascadingSteps')
        ? Boolean(user.showAllCascadingSteps)
        : null,

      providers: getSelectionListItems({
        originalList: user.enabledProviders,
        selectedList: user.enabledProviders,
        getText: ({ text, id }) => {
          return getNameWithId(this.getProjectName(text), id);
        },
      }),
      favoriteCurrencies:
        (currency &&
          getSelectionListItems({
            originalList: currency,
            selectedList: user.favoriteCurrencies,
            getText: ({ id }) => id,
          })) ||
        [],
      isPaymentIdGeneratorEnabled: user.isPaymentIdGeneratorEnabled,
      availableTimezones: timezones.list.map((timezone) => {
        return {
          value: timezone.name,
          label: this.getTimezoneName(timezone),
        };
      }),
      availableLanguages:
        contactLanguages &&
        getCustomSelectItems({
          list: contactLanguages,
        }),
      availableInterfaceLanguages:
        interfaceLanguages &&
        getCustomSelectItems({
          list: interfaceLanguages,
        }),
    };
  }

  onEvent = async ({ data: { payload } }) => {
    if (payload.validationErrors) {
      this.setState({ validationErrors: payload.validationErrors });
    } else {
      this.setState({
        fields: this.getUserFieldsForState(),
        validationErrors: {},
      });
    }
  };
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  currency: state.filtersValues?.currency?.list,
  timezones: state.dictionaries.timezones,
  contactLanguages: state.filtersValues?.contactLanguage?.list,
  interfaceLanguages: state.filtersValues?.interfaceLanguage?.list,
});

export default connect(mapStateToProps)(ProfileInfoContainer);
