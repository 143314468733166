import React, { PureComponent, ReactElement } from 'react';
import classNames from 'classnames';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import OperationCardContainer from 'components/operationCard/OperationCardContainer';
import SavedEntitiesContainer from 'components/savedEntities';
import TopPanelContainer from 'components/topPanel/TopPanelContainer';
import DataListContainer from 'components/dataListContainer';

import MetricService from 'helpers/metricService/MetricService';

import {
  minOperationsTableColumnsWidth,
} from './constants';
import tableNames from 'constants/tableNames';
import savedEntities from 'constants/savedEntities';
import quickFiltersConfig from 'pages/operations/quickFiltersConfig';
import { Column, Header, Row } from 'components/ui/table';
import ItemConfiguration from 'types/ItemConfiguration';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Lock from 'components/tableHeaders/components/lock/Lock';
import setNoChangeWordOrder from 'helpers/setNoChangeWordOrder';
import SortingCell from 'components/tableHeaders/components/sortingCell/SortingCell';
import RoundButton from 'components/ui/roundButton';
import './payments.scss';
import { SortingType } from '../../types/sortings';
import { getOperations } from "../../api/operations";

interface OwnProps {
  id?: string;
  isDemoUser: boolean;
  configuration: ItemConfiguration[];
  feature_configuration: ItemConfiguration[];
  sort: {
    field: string;
    order: SortingType;
  };
  goToOperation: (data) => void;
  openTabWithPayment: (data) => void;
  isRefundEnabled: boolean;
}

type Props = OwnProps & IntlProps;

class OperationsPage extends PureComponent<Props> {
  render() {
    return (
      <PageTemplate.Main>
        <PageTemplate.Entities>
          <SavedEntitiesContainer entityKey={savedEntities.operations} />
        </PageTemplate.Entities>
        {this.renderContent()}
      </PageTemplate.Main>
    );
  }

  renderContent() {
    const { id } = this.props;

    if (id) {
      return (
        <OperationCardContainer id={id} savedEntity={savedEntities.operations} />
      );
    }
    return (
      <PageTemplate.Container>
        <TopPanelContainer
          name={tableNames.operations}
          filtersConfig={quickFiltersConfig}
          onResetCallback={() => {
            MetricService.send({
              action: 'click',
              actionKey: 'payments.registry.filterDeactivation',
            });
          }}
          isFeatureConfigurationButton={false}
        />
        <DataListContainer
          tableName={tableNames.operations}
          apiRequest={getOperations}
          minColumnsWidth={minOperationsTableColumnsWidth}
          rowRender={this.rowRender}
          headerRender={this.headerRender()}
        />
      </PageTemplate.Container>
    );
  }

  headerRender = () => {
    return (
      <Header>
        {this.props.configuration.map((config) => {
          let content: string | ReactElement = this.props.getTranslate(
            config.localId
          );
          const isFixed = Boolean(config.fixed);

          if (config.id === 'operation_id') {
            content = (
              <Lock
                content={this.props.getTranslate(config.localId)}
                tableName={tableNames.operations}
                isFixed={isFixed}
              />
            );
          } else if (
            config.id === 'creationDate' ||
            config.id === 'updatedDate'
          ) {
            content = (
              <SortingCell
                tableName={tableNames.operations}
                id={config.id}
                sort={this.props.sort}>
                {content}
              </SortingCell>
            );
          }

          return (
            <Column
              id={config.id}
              key={config.id}
              customClass={isFixed ? 'ui-table__column_fixed' : ''}
              modifier={config.id}
              content={content}
              minColumnsWidth={minOperationsTableColumnsWidth}
            />
          );
        })}
      </Header>
    );
  };

  rowRender = (data) => {
    return (
      <Row
        onClick={() => data.handleDoubleClick(this.props.goToOperation)}
        key={data.operationId}
        customClass='ui-table__row_clickable'>
        {this.props.configuration.map((config) => {
          if (config.id === 'operation_id') {
            return (
              <Column
                id={config.id}
                key={/*TODO: REVISE THIS KEY!*/`data-${data.operationId}-${config.id}`}
                customClass={classNames('page-payments__id', {
                  'ui-table__column_fixed': config.fixed,
                  'page-payments__id_no-action': !this.props.isRefundEnabled,
                })}
                modifier={config.id}
                content={
                  <>
                    <span className='ui-table__payment-id-and-button-open-in-new-tab'>
                      <RoundButton
                        status='light'
                        customClass='page-payments__button-open-in-new-tab'
                        icon='im-Tab-Open'
                        iconSize={11}
                        tooltip={this.props.getTranslate(
                          `payments.registry.openTab.button`
                        )}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.stopPropagation();
                          this.props.openTabWithPayment(data);
                          MetricService.send({
                            action: 'click',
                            actionKey: 'payments.registry.openTab',
                          });
                        }}
                      />
                      {setNoChangeWordOrder(data[config.id])}
                    </span>
                  </>
                }
                minColumnsWidth={minOperationsTableColumnsWidth}
                columnWidths={data.columnWidths}
                data={data}
              />
            );
          }

          return (
            <Column
              id={config.id}
              key={`data-${data.operationId}-${config.id}`}
              modifier={config.id}
              content={data[config.id] || '—'}
              minColumnsWidth={minOperationsTableColumnsWidth}
              columnWidths={data.columnWidths}
              data={data}
              params={{
                ...config,
              }}
            />
          );
        })}
      </Row>
    );
  };
}

export default addTranslation(OperationsPage);
