import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEqual, throttle } from 'lodash';

import { addListeners, IListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';

import { createUser, getTeamUser, updateUser } from 'api/team';
import {
  getUser,
  createUser as createUserAdmin,
  updateUser as updateUserAdmin,
} from 'api/admin';
import { StoreProps } from 'store';
import { openModal } from 'actions/modal';
import Loader from 'components/ui/loader';
import TrustedIP from 'components/trustedIP/TrustedIP';
import PermissionsTree from 'components/permissionsTree';
import UserBuilder from './UserBuilder';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import UserFormSection from './UserFormSection';

import { fieldsConfig, getAdminFieldsConfig } from './FieldsConfig';
import { AnyObject } from 'types/Common';
import PermissionsTreeType from 'types/PermissionsTree';
import MetricService from 'helpers/metricService/MetricService';
import { AdminUserFields, UserFields } from './UserFieldsInterface';
import {
  getFieldsForState,
  getPermissionTree,
  validateAdminFields,
  validateUserFields,
} from './helpers';
import checkFilters from 'helpers/checkFilters';
import { scrollToError } from 'components/formFields/helpers';
import { DictionaryMultiSelect } from 'types/FilterValue';
import filtersValuesPerSection from 'constants/filtersValuesPerSection';
import breakpoints from 'constants/breakpoints';
import { loadDictionary } from 'api/dictionaries';
import getSelectionListItems from 'creators/getSelectionListItems';
import {
  availableRoles,
  requiredPermissions,
} from 'components/userBuilder/constants';

interface OwnProps {
  isAdmin: boolean;
}

interface ConnectedProps {
  user: {
    availableProjects: AnyObject[];
    projectsAvailable?: AnyObject[];
    enabledProviders: AnyObject[];
    userRoles: string[];
    isChargebackFeatureEnabled: boolean;
    isFinancialReportMailingAvailable: boolean;
    isFraudMailingAvailable: boolean;
  };
  userId: string | null;
  isEditing: boolean;
  roleWithPermissionToGrant: AnyObject;
  roleToGrant: AnyObject;
  roleToSee: AnyObject;
  wlList: AnyObject[];
  isLoadingDictionaries: boolean;
}

type RouterProps = WithRouterProps<{ id?: string }>;

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithPermissions &
  RouterProps;

export type UserBuilderProps = Props;

interface State {
  isFetch: boolean;
  isLoading: boolean;
  fields: UserFields | AdminUserFields;
  canEdit: boolean;
  permissionsTree: PermissionsTreeType | null;
  isSubmitted: boolean;
  validationErrors: AnyObject;
  backendError: boolean;
  isChangePasswordAvailable: boolean;
  isEnabledIps: boolean;
  selectedByDefault: any[];
  project?: AnyObject;
  providers?: AnyObject;
  isOpenedAdditionalBlock: boolean;
  isMobile: boolean;
  innerRoles: {
    id: string;
    isSelected: boolean;
    text: string;
  }[];
  innerPermissionsDisabled: string[];
  innerPermissionsTree: PermissionsTreeType | null;
}

const initialData = {
  id: '',
  email: '',
  username: '',
  contactPhone: '',
  password: '',
  wlId: [],
  enabledProviders: [],
  roles: [],
  allowedIps: [],
  permissionsDisabled: [],
};

@addListeners([
  Messages.Team_UpdateUser,
  Messages.Team_CreateUser,
  Messages.User_Update,
  Messages.User_Create,
])
class UserBuilderContainer
  extends Component<Props, State>
  implements IListeners
{
  private permissionsMap: AnyObject;
  private readonly getFieldsForState: (
    data,
    isDisabled?
  ) => UserFields | AdminUserFields;
  private readonly updateUser: (userId) => void;
  private readonly getUser: (userId) => AnyObject;
  private readonly createUser: (data) => void;

  constructor(props: Props) {
    super(props);

    const { isAdmin } = props;
    this.getFieldsForState = getFieldsForState.bind(this);
    this.updateUser = isAdmin ? updateUserAdmin : updateUser;
    this.getUser = isAdmin ? getUser : getTeamUser;
    this.createUser = isAdmin ? createUserAdmin : createUser;
    this.handleResize = throttle(this.handleResize, 300);

    this.state = {
      isFetch: false,
      isLoading: false,
      canEdit: true,
      isEnabledIps: false,
      fields:
        props.roleToSee &&
        props.roleToGrant &&
        this.getFieldsForState(initialData),
      permissionsTree: null,
      isSubmitted: false,
      validationErrors: {},
      selectedByDefault: [],
      isChangePasswordAvailable: false,
      backendError: false,
      isOpenedAdditionalBlock: false,
      isMobile: window.innerWidth <= breakpoints.userBuilder,
      innerRoles: [],
      innerPermissionsDisabled: [],
      innerPermissionsTree: {},
    };

    this.permissionsMap = {};
  }

  async componentDidMount() {
    let user;
    if (this.props.userId) {
      user = await this.getUser(this.props.userId);
    }

    await checkFilters(this.getFiltersToPreload(user));

    await this.init(user);
    window.addEventListener('resize', this.handleResize);
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) {
    const { fields } = this.state;
    if (
      !isEqual(prevState.fields?.roles, fields?.roles) ||
      !isEqual(prevState.innerRoles, this.state.innerRoles)
    ) {
      this.createPermissionsTree();
      this.syncDisabledPermissions();
    }

    if (prevState.isMobile !== this.state.isMobile && !this.state.isMobile) {
      this.createPermissionsTree();
      this.syncDisabledPermissions();
      this.setState(() => ({
        innerRoles: [],
        innerPermissionsDisabled: [],
        innerPermissionsTree: null,
        isOpenedAdditionalBlock: false,
      }));
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { isEditing, isAdmin, user } = this.props;
    const {
      fields,
      isFetch,
      permissionsTree,
      isSubmitted,
      isLoading,
      backendError,
      canEdit,
      isOpenedAdditionalBlock,
      isMobile,
      innerPermissionsTree,
    } = this.state;

    if (!isFetch || !fields) {
      return <Loader />;
    }
    const tree = isMobile ? innerPermissionsTree : permissionsTree;
    return (
      <UserBuilder
        canEdit={Boolean(canEdit || isAdmin)}
        isEditing={isEditing}
        isLoading={isLoading}
        isSubmitted={isSubmitted}
        canSendForm={this.canSendForm()}
        onSend={() => this.send()}
        isAdmin={isAdmin}
        backendError={backendError}
        fields={fields as UserFields}
        renderForm={this.renderForm}
        backToForm={this.backToForm}
        selectedPermissionsAmount={this.getSelectedPermissionsAmount()}
        toggleAdditionalBlock={this.toggleRolesPermissions}
        isOpenedAdditionalBlock={isOpenedAdditionalBlock}>
        {tree && (
          <PermissionsTree
            roles={isMobile ? this.state.innerRoles : fields.roles}
            userRoles={user.userRoles}
            canEdit={Boolean(canEdit || isAdmin)}
            isAdmin={isAdmin}
            isEditing={isEditing}
            onChangePermission={this.changePermission}
            onChangeRole={this.changeField}
            toggleRolesPermissions={this.toggleRolesPermissions}
            checkPermissionsChanges={this.checkPermissionChanges}
            customClass='user-builder__permissions'
            permissionsDisabled={
              isMobile
                ? this.state.innerPermissionsDisabled
                : fields?.permissionsDisabled
            }
            tree={tree}
          />
        )}
      </UserBuilder>
    );
  }

  init = async (user: AnyObject | null = null) => {
    const { roleToGrant, roleToSee, isAdmin, isEditing, isEnabled } =
      this.props;

    const canEdit =
      (isAdmin ? true : isEnabled(Messages.Team_UpdateUser)) &&
      (isAdmin || !isEditing || (!!user && this.isUserEditable(user)));
    if (roleToGrant || roleToSee) {
      this.setState(
        {
          fields: this.getFieldsForState(user || initialData, !canEdit),
          isEnabledIps: !!(user || initialData).allowedIps.length,
          isChangePasswordAvailable: user?.isChangePasswordAvailable,
        },
        this.createPermissionsTree
      );
    }
    this.setState({
      isFetch: true,
      canEdit,
    });
  };

  getFiltersToPreload = (user) => {
    return filtersValuesPerSection['userBuilderAdmin']
  };

  setList = (selected, dictionary, fieldName) => {
    return (selected || [])
      .map((item) => ({
        ...item,
        id: item[fieldName],
      }))
      .concat(dictionary || []);
  };

  renderForm = () => {
    const {
      fields,
      validationErrors,
      isChangePasswordAvailable,
      canEdit,
      isEnabledIps,
    } = this.state;

    const { getTranslate, isEditing, isAdmin, user } =
      this.props;
    return (
      <>
        <UserFormSection
          fields={fields}
          filtersValues={{}}
          updateFiltersValues={this.updateFiltersValues}
          disableForm={!canEdit && !isAdmin}
          fieldsConfig={
            isAdmin
              ? getAdminFieldsConfig(isEditing, !isChangePasswordAvailable)
              : fieldsConfig(isEditing)
          }
          getTranslate={getTranslate}
          isEditing={isEditing}
          validationErrors={validationErrors}
          onChangeField={this.changeField}
          isChargebackFeatureEnabled={user.isChargebackFeatureEnabled}
          isFinancialReportMailingAvailable={
            user.isFinancialReportMailingAvailable
          }
          isFraudMailingAvailable={user.isFraudMailingAvailable}
        />
        <TrustedIP
          canEdit={canEdit}
          onChangeField={this.changeField}
          getTranslate={getTranslate}
          fields={fields as UserFields}
          validationErrors={validationErrors}
          isEnabled={isEnabledIps}
          onToggleIps={this.toggleIps}
        />
      </>
    );
  };

  updateFiltersValues = (key, value) => {
    this.setState({ [key]: value } as Pick<State, keyof State>);
  };

  toggleIps = (isEnabledIps) => {
    this.setState({ isEnabledIps });
  };

  backToForm = async () => {
    const { isEditing, isAdmin } = this.props;
    if (!isEditing) {
      if (isAdmin) {
        await checkFilters(this.getFiltersToPreload(null));
      }
      this.setState(
        {
          fields: this.getFieldsForState(initialData),
          isEnabledIps: false,
        },
        this.createPermissionsTree
      );
    }
    this.setState((state) => ({
      isSubmitted: false,
      isEnabledIps: !!state.fields.ipList.length,
    }));
  };

  async send() {
    const { userId, isEditing, isAdmin } = this.props;
    const { backendError, canEdit } = this.state;

    if (!(canEdit || isAdmin)) return;

    let action;
    if (isEditing) {
      if (isNotAvailableForSupport(Messages.Team_UpdateUser)) return false;
      action = this.updateUser;
    } else {
      if (isNotAvailableForSupport(Messages.Team_CreateUser)) return false;
      action = this.createUser;
    }

    this.setState({ isLoading: true, isSubmitted: false });

    try {
      const data: any = this.getFieldsForBackend();
      if (isEditing) {
        data.userId = userId;
      }

      await action(data);

      if (backendError) {
        this.setState({ backendError: false });
      }
    } catch (error) {
      const { payload, status } = error;
      if (status === 'error') {
        this.setState({ backendError: true });
      }
      if (payload && payload.validationErrors) {
        this.setState({
          validationErrors: payload.validationErrors,
        });
        scrollToError(payload.validationErrors);
      }
    } finally {
      this.setState({ isLoading: false });
    }
  }

  canSendForm(): boolean {
    const { fields, isChangePasswordAvailable } = this.state;
    const { isAdmin } = this.props;
    if (isAdmin) {
      return validateAdminFields(fields, isChangePasswordAvailable);
    }
    return validateUserFields(fields);
  }

  applyRolesPermissions = () => {
    const updatedFields = {
      ...this.state.fields,
      roles: this.state.innerRoles,
      permissionsDisabled: this.state.innerPermissionsDisabled,
    };

    updatedFields.isChargebackMailingEnabled =
      this.checkMailing('isChargebackMailingEnabled', this.state.innerRoles) &&
      !this.state.innerPermissionsDisabled?.includes(
        requiredPermissions['isChargebackMailingEnabled']
      );

    updatedFields.isFinancialReportMailingEnabled =
      this.checkMailing(
        'isFinancialReportMailingEnabled',
        this.state.innerRoles
      ) &&
      !this.state.innerPermissionsDisabled?.includes(
        requiredPermissions['isFinancialReportMailingEnabled']
      );

    updatedFields.isFraudMailingEnabled =
      this.checkMailing('isFraudMailingEnabled', this.state.innerRoles) &&
      !this.state.innerPermissionsDisabled?.includes(
        requiredPermissions['isFraudMailingEnabled']
      );

    this.setState((state) => ({
      fields: updatedFields,
      permissionsTree: state.innerPermissionsTree,
    }));
  };

  changeField = (key: string, value: any, countryCode?) => {
    const { fields } = this.state;

    const updatedFields = {
      ...fields,
      [key]: value,
    };

    if (key === 'contactPhone') {
      updatedFields.contactPhoneCountry = countryCode;
    }

    if (this.state.isMobile) {
      if (key === 'roles') {
        this.setState({
          innerRoles: value,
        });
      } else if (key === 'permissionsDisabled') {
        this.setState({
          innerPermissionsDisabled: value,
        });
      } else {
        this.setState({
          fields: updatedFields,
          validationErrors: {},
        });
      }
      return;
    } else if (key === 'roles') {
      updatedFields.isChargebackMailingEnabled = this.checkMailing(
        'isChargebackMailingEnabled',
        value
      );
      updatedFields.isFinancialReportMailingEnabled = this.checkMailing(
        'isFinancialReportMailingEnabled',
        value
      );
      updatedFields.isFraudMailingEnabled = this.checkMailing(
        'isFraudMailingEnabled',
        value
      );
    }

    this.setState({
      fields: updatedFields,
      validationErrors: {},
    });
  };

  checkMailing = (name, roles) => {
    return !!roles.find((item) => {
      return availableRoles[name].some((role) => {
        return item.isSelected && item.id === role;
      });
      /*return (
        (item.id === 'merchant_admin' && item.isSelected) ||
        (item.id === 'risk' && item.isSelected)
      );*/
    });
  };

  changePermission = (key: string) => {
    const {
      fields,
      fields: { permissionsDisabled },
      innerPermissionsDisabled,
      isMobile,
    } = this.state;
    const permissionsDisabledSource = isMobile
      ? innerPermissionsDisabled
      : permissionsDisabled;
    const copyPermissions = [...permissionsDisabledSource];
    const index = permissionsDisabledSource.indexOf(key);
    if (index >= 0) {
      copyPermissions.splice(index, 1);
    } else {
      copyPermissions.push(key);
    }

    const updatedFields: {
      permissionsDisabled;
      isChargebackMailingEnabled?;
      isFraudMailingEnabled?;
    } = {
      permissionsDisabled: copyPermissions,
    };
    if (copyPermissions.includes('chargeback_manage')) {
      updatedFields.isChargebackMailingEnabled = false;
    }
    if (copyPermissions.includes('rcs_view')) {
      updatedFields.isFraudMailingEnabled = false;
    }

    if (this.state.isMobile) {
      this.setState({
        innerPermissionsDisabled: updatedFields.permissionsDisabled,
      });
    } else {
      this.setState({
        fields: {
          ...fields,
          ...updatedFields,
        },
      });
    }
  };

  createPermissionsTree = () => {
    const { roleWithPermissionToGrant } = this.props;
    const { fields, isMobile, innerRoles, isOpenedAdditionalBlock } =
      this.state;

    const { permissionsTree, permissionsMap } = getPermissionTree(
      roleWithPermissionToGrant,
      isMobile && isOpenedAdditionalBlock ? innerRoles : fields.roles
    );
    this.permissionsMap = permissionsMap;

    if (isMobile && isOpenedAdditionalBlock) {
      this.setState({
        innerPermissionsTree: permissionsTree,
      });
    } else {
      this.setState({
        permissionsTree,
      });
    }
  };

  syncDisabledPermissions() {
    const { fields, innerPermissionsDisabled, isMobile } = this.state;
    const permissionsDisabledSource = isMobile
      ? innerPermissionsDisabled
      : fields.permissionsDisabled;
    const permissionsDisabled = permissionsDisabledSource.filter((id) => {
      return this.permissionsMap[id];
    });
    this.changeField('permissionsDisabled', permissionsDisabled);
  }

  setIsSubmitted = () => {
    this.setState({
      isSubmitted: true,
    });
  };

  isMerchantAdmin = (userRoles) => userRoles.includes('merchant_admin');

  isUserEditable = (user) => {
    const { user: currentUser, isAdmin } = this.props;
    return (
      isAdmin ||
      !(
        this.isMerchantAdmin(currentUser.userRoles) &&
        this.isMerchantAdmin(user.roles)
      )
    );
  };

  getFieldsForBackend() {
    const { fields } = this.state;
    const { isAdmin } = this.props;
    const {
      username,
      email,
      permissionsDisabled,
      roles,
      contactPhone,
      contactPhoneCountry,
      isChargebackMailingEnabled,
      isFinancialReportMailingEnabled,
      isFraudMailingEnabled,
    } = fields;

    const commonFields = {
      username,
      email,
      contactPhone,
      contactPhoneCountry,
      permissionsDisabled,
      isChargebackMailingEnabled,
      isFinancialReportMailingEnabled,
      isFraudMailingEnabled,
      roles: roles
        .filter(({ id, isSelected }) => isSelected && id)
        .map(({ id }) => id),
      ipRanges: this.getIpRanges(),
    };
    if (isAdmin) {
      const { password, wlId } = fields as AdminUserFields;
      return {
        ...commonFields,
        password,
        wlId: wlId.value?.value,
      };
    }
    return {
      ...commonFields,
    };
  }

  getIpRanges() {
    const { ipList } = this.state.fields as UserFields;
    if (!this.state.isEnabledIps) {
      this.setState((state) => {
        return {
          fields: {
            ...state.fields,
            ipList: [],
          },
        };
      });
      return [];
    }
    const ipRanges: string[][] = [];
    ipList.forEach(({ from, to }) => {
      if (from && !to) {
        ipRanges.push([from, from]);
      } else if (!from && to) {
        ipRanges.push([to, to]);
      } else if (from && to) {
        ipRanges.push([from, to]);
      }
    });
    return ipRanges;
  }

  toggleRolesPermissions = (isOpenedAdditionalBlock, isApply?) => {
    this.setState(
      {
        isOpenedAdditionalBlock,
      },
      () => {
        if (isApply) {
          this.applyRolesPermissions();
        }
        if (isOpenedAdditionalBlock) {
          this.setState((state) => ({
            innerRoles: state.fields.roles,
            innerPermissionsDisabled: state.fields.permissionsDisabled,
          }));
        }
      }
    );
  };

  checkPermissionChanges = () => {
    if (
      isEqual(this.state.innerRoles, this.state.fields.roles) &&
      isEqual(
        this.state.innerPermissionsDisabled,
        this.state.fields.permissionsDisabled
      )
    ) {
      this.toggleRolesPermissions(false);
    } else {
      this.confirmNoSaves();
    }
  };

  confirmNoSaves = () => {
    this.props.dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: 'projects.urls.modal.urlChangeDiscard.header',
          text: 'projects.urls.modal.urlChangeDiscard.label',
        },
        callback: (isAgree) => {
          if (isAgree) {
            return this.toggleRolesPermissions(false);
          }
        },
      })
    );
  };

  getSelectedPermissionsAmount = () => {
    if (!this.state.permissionsTree) return 0;
    const allPermissionsLength = Object.values(
      this.state.permissionsTree
    ).reduce((amount, item) => {
      return amount + Object.values(item.permissions).length;
    }, 0);
    return allPermissionsLength - this.state.fields.permissionsDisabled.length;
  };

  handleResize = () => {
    if (window.innerWidth > breakpoints.userBuilder) {
      this.setState({ isMobile: false });
    } else {
      this.setState({ isMobile: true });
    }
  };

  onEvent({ data }) {
    const { isEditing } = this.props;
    if (!data.payload.validationErrors) {
      this.setIsSubmitted();
    }

    if (data.payload.id) {
      this.setState({
        fields: this.getFieldsForState(data.payload),
      });
    }
    if (!this.props.isAdmin) {
      MetricService.send({
        action: 'click',
        actionKey: isEditing
          ? 'myTeam.editUser.save.yes'
          : 'myTeam.newUser.create',
      });
    }
  }
}

const mapStateToProps = (
  state,
  ownProps: OwnProps & RouterProps
): ConnectedProps => {
  const userId = ownProps.match?.params?.id || null;
  const { filtersValues } = state;
  return {
    user: state.user,
    userId,
    isEditing: Boolean(userId),
    roleWithPermissionToGrant: filtersValues.roleWithPermissionToGrant?.list,
    roleToGrant: filtersValues?.roleToGrant?.list,
    roleToSee: filtersValues?.roleToSee?.list,
    wlList: filtersValues?.wlList?.list,
    isLoadingDictionaries: !filtersValues,
  };
};

export default withRouter(
  connect(mapStateToProps)(addTranslation(addPermissions(UserBuilderContainer)))
);
