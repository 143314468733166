import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import { APP_PADDING } from '../../../../../../constants/ui';

import {
  IFormValues,
  IOnChange,
  IDictionary,
  IValidateHandler,
} from 'types/Analytics';
import { AdvancedAnalyticsEntityType } from '../AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';

import CustomScrollbar from 'components/ui/customScrollbar';
import { InOutTableGroupByParams, InOutTableFilters } from './InOutFormConfig';
import {
  chartParams,
  filters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/OperationsFormConfig';
import { topTenChartParams, topTenChartFilters } from './Top10FormConfig';
import {
  DeclineReasonsParams,
  DeclineReasonsFilters,
} from './DeclineReasonsFormConfig';
import {
  FraudReportParams,
  FraudReportFilters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/FraudReportFormConfig';
import {
  ChargebackParams,
  ChargebackFilters,
} from 'components/modal/modalList/graphDetails/components/settingsForm/ChargebackFormConfig';
import { PaymentChartParams, paymentFilters } from './PaymentsFormConfig';
import {
  DeclineCodesChartParams,
  DeclineCodesFilters,
} from './DeclineCodesFormConfig';

import { GetRenderableField } from '../../services/ServiceAdvancedAnalyticsEntityTypesFormGenerator';

import './settingsForm.scss';

interface Props extends IntlProps, WithPermissions {
  selectedType?: string;
  validationErrors: { [key: string]: string };
  formValidationErrors: { [key: string]: string };
  values: IFormValues;
  projects: IDictionary;
  country: IDictionary;
  currency: IDictionary;
  paymentMethod: IDictionary;
  analyticsBrand: IDictionary;
  paymentMethodFromDictionary: IDictionary;
  issuerBank: IDictionary;
  onChange: IOnChange;
  readonly?: boolean;
  validateHandler: IValidateHandler;
  formValidatedFields: string[];
  pristineFields: { [key: string]: boolean };
}

class SettingsForm extends React.PureComponent<Props> {
  ref?: React.Ref<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.whatToRender = this.whatToRender.bind(this);
    this.renderField = this.renderField.bind(this);
  }

  render() {
    const { selectedType, readonly } = this.props;

    if (!selectedType && !readonly) {
      return null;
    }
    return (
      <div className='settings-form'>
        <CustomScrollbar
          getRef={(ref) => {
            this.ref = ref;
          }}
          hideHorizontal
          customHeight={300}>
          {this.whatToRender()}
        </CustomScrollbar>
      </div>
    );
  }

  get filtersHeader() {
    const { getTranslate } = this.props;

    return (
      <div className='settings-form__header'>
        {getTranslate('analytics.editForm.filters.filters.label')}
      </div>
    );
  }

  get chartParamsHeader() {
    const { getTranslate } = this.props;

    return (
      <div className='settings-form__header'>
        {getTranslate('analytics.editForm.chartParameters.operations.label')}
      </div>
    );
  }

  whatToRender() {
    const { selectedType, formValidationErrors, getTranslate } = this.props;

    switch (selectedType) {
      case AdvancedAnalyticsEntityType.operations: {
        return (
          <>
            {this.chartParamsHeader}
            {chartParams.map(this.renderField)}

            {this.filtersHeader}
            {filters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.payments: {
        return (
          <>
            {this.chartParamsHeader}
            {PaymentChartParams.map(this.renderField)}

            {this.filtersHeader}
            {paymentFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.inout: {
        const { formValidatedFields, pristineFields } = this.props;

        const shouldShowFormError = formValidatedFields.some(
          (field) => !pristineFields[field]
        );

        return (
          <>
            <div className='settings-form__header'>
              {getTranslate('analytics.editForm.tableParameters.label')}
            </div>
            {InOutTableGroupByParams.map(this.renderField)}

            <div className='settings-form__row'>
              {shouldShowFormError &&
                Object.entries(formValidationErrors).map(
                  ([errId, err]) =>
                    err !== undefined && (
                      <p
                        key={`formErr-${errId}`}
                        className='settings-form__error'>
                        {err}
                      </p>
                    )
                )}
            </div>

            {this.filtersHeader}
            {InOutTableFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.topten: {
        return (
          <>
            {this.chartParamsHeader}
            {topTenChartParams.map(this.renderField)}

            {this.filtersHeader}
            {topTenChartFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.declineCodes: {
        return (
          <>
            {this.chartParamsHeader}
            {DeclineCodesChartParams.map(this.renderField)}

            {this.filtersHeader}
            {DeclineCodesFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.declineReasons: {
        return (
          <>
            <div className='settings-form__header'>
              {getTranslate('analytics.editForm.tableParameters.label')}
            </div>
            {DeclineReasonsParams.map(this.renderField)}

            {this.filtersHeader}
            {DeclineReasonsFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.chargeback: {
        return (
          <>
            {this.chartParamsHeader}
            {ChargebackParams.map(this.renderField)}

            {this.filtersHeader}
            {ChargebackFilters.map(this.renderField)}
          </>
        );
      }

      case AdvancedAnalyticsEntityType.fraud_report: {
        return (
          <>
            {this.chartParamsHeader}
            {FraudReportParams.map(this.renderField)}

            {this.filtersHeader}
            {FraudReportFilters.map(this.renderField)}
          </>
        );
      }
    }
  }

  renderField(formItem) {
    const {
      values,
      onChange,
      getTranslate,
      validationErrors,
      country,
      currency,
      projects,
      paymentMethod,
      analyticsBrand,
      paymentMethodFromDictionary,
      issuerBank,
      validateHandler,
      isEnabled,
    } = this.props;
    const { id, label, tooltip } = formItem;

    return GetRenderableField({
      formDescriptorItem: formItem,
      onChange,
      values,
      id,
      getTranslate,
      label,
      tooltip,
      validationErrors,
      panelPosition: {
        height: '100%',
        width: '50%',
        minWidth: 450,
        top: APP_PADDING,
        left: 'auto',
        right: APP_PADDING,
      },
      dictionaries: {
        country,
        currency,
        projects,
        paymentMethod,
        analyticsBrand,
        paymentMethodFromDictionary,
        issuerBank,
      },
      validateHandler,
      isEnabled,
    });
  }
}

export default addTranslation(addPermissions(SettingsForm));
