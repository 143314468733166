import React, { PureComponent, Fragment } from 'react';
import ReactCountryFlag from 'react-country-flag';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';

import Avatar from 'components/ui/avatar';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import Switch from 'components/ui/switch';
import CustomSelect from 'components/ui/customSelect';
import SelectionList from 'components/ui/selectionList';
import Form from 'components/ui/form';
import Loader from 'components/ui/loader';
import ListView from 'components/ui/listView';
import PhoneInput from 'components/ui/phoneInput';

import socialIcons from 'images/social';
import getCountryByPhone from 'helpers/getCountryByPhone';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import FieldsType from './FieldsType';
import { AnyObject } from 'types/Common';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';

import './profileInfo.scss';

interface OwnProps extends IntlProps, WithPermissions {
  user: any;
  fields: FieldsType;
  validationErrors: AnyObject;
  isEditable: boolean;
  isLoading: boolean;
  isSaving: boolean;
  isUploadingAvatar: boolean;
  isChargebackMailingAvailable: boolean;
  isFinancialReportMailingAvailable: boolean;
  isFraudMailingAvailable: boolean;
  onChangeEditable: (isEditable: boolean) => void;
  onSave: () => void;
  onCancelEdit: () => void;
  onChangeField: (key: string, value: any, option?) => void;
  onChangeAvatar: (event) => void;
  customClass: string;
}

type Props = OwnProps & IntlProps & WithPermissions;

class ProfileInfo extends PureComponent<Props> {
  render() {
    const { isEditable, isSaving, onSave, customClass } = this.props;

    return (
      <div
        className={classNames('profile-info', customClass, {
          'profile-info_edit': isEditable,
        })}>
        <div className='card card_l'>
          <div className='card__container'>
            <Form onSubmit={() => isEditable && onSave()}>
              {this.renderTopBlock()}
              {this.renderMainArea()}
            </Form>
          </div>
          {isSaving && <Loader />}
        </div>
      </div>
    );
  }

  renderEditButtons() {
    const { fields, isEditable, onCancelEdit, getTranslate } = this.props;

    if (!this.canRenderEditButton()) return null;

    return (
      <div className='profile-info__buttons'>
        {isEditable ? (
          <Fragment>
            <Button
              text={getTranslate('common.cancel.button')}
              status='outline'
              size='normal'
              customClass='profile-info__button'
              id='profile-button-cancel'
              onClick={onCancelEdit}
            />
            <Button
              text={getTranslate('common.save.button')}
              size='normal'
              status='primary'
              type='submit'
              customClass='profile-info__button'
              id='profile-button-submit'
            />
          </Fragment>
        ) : (
          <Button
            text={getTranslate('profile.edit.button')}
            status='outline'
            size='normal'
            customClass='profile-info__button'
            id='profile-button-edit'
            onClick={this.startEdit}
            disabled={!fields.favoriteCurrencies}
          />
        )}
      </div>
    );
  }

  canRenderEditButton(): boolean {
    const { isEnabled, isDisabledByReason } = this.props;
    return (
      isEnabled(Messages.Profile_Update) ||
      isDisabledByReason(
        Messages.Profile_Update,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      )
    );
  }

  startEdit = () => {
    const { onChangeEditable } = this.props;
    if (isNotAvailableForSupport(Messages.Profile_Update)) return false;
    onChangeEditable(true);
  };

  renderTopBlock() {
    const {
      user,
      fields,
      validationErrors,
      isEditable,
      isUploadingAvatar,
      onChangeAvatar,
      onChangeField,
      getTranslate,
    } = this.props;

    return (
      <div className='profile-info__block profile-info__block_top'>
        <div className='profile-info__item profile-info__item_space-between'>
          <div className='profile-info__avatar'>
            <Avatar
              id='profile-avatar'
              user={user}
              size='large'
              isEdit={isEditable}
              isLoading={isUploadingAvatar}
            />
            {isEditable && (
              <div className='profile-info__avatar-edit'>
                <input
                  className='profile-info__avatar-input'
                  type='file'
                  onChange={onChangeAvatar}
                />
                <span className='profile-info__avatar-edit-label'>
                  {getTranslate('profile.userprofile.newPicture.button')}
                </span>
              </div>
            )}
          </div>
          {this.renderEditButtons()}
        </div>
        <div className='profile-info__item'>
          {isEditable ? (
            <Input
              id='profile-input-name'
              placeholder=''
              label='profile.userprofile.name.label'
              value={fields.name}
              error={validationErrors?.name}
              customClass='profile-info__input-name'
              onChange={(event) => onChangeField('name', event.target.value)}
              modern
            />
          ) : (
            <span id='profile-name' className='profile-info__name'>
              {user.name}
            </span>
          )}
        </div>
        <div className='profile-info__item profile-info__item_roles utils-flex'>
          {user.userRoles.map((role) => {
            return (
              <div key={role} className='profile-info__role'>
                {role}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderMainArea() {
    const {
      fields,
      isEditable,
      isChargebackMailingAvailable,
      isFinancialReportMailingAvailable,
      isFraudMailingAvailable,
      getTranslate,
    } = this.props;

    return (
      <div className='profile-info__block profile-info__block_main-area'>
        {isEditable ? (
          this.renderFields()
        ) : (
          <>
            <div className='profile-info__row profile-info__row_col'>
              <div id='profile-projects-key' className='profile-info__key'>
                {getTranslate('profile.userprofile.assignedProviders.label')}
                <span className='profile-info__amount'>
                  ({this.getSelectedProviders().length})
                </span>
              </div>

              <div id='profile-projects-value' className='profile-info__value'>
                <ListView
                  list={this.getSelectedProviders()}
                  customClass='profile-info__project-list'
                />
              </div>
            </div>
            
            <div className='profile-info__row'>
              <div id='profile-tz-key' className='profile-info__key'>
                {getTranslate('profile.userprofile.timeZone.label')}
              </div>

              <div id='profile-tz-value' className='profile-info__value'>
                {fields.timezone.label}
              </div>
            </div>
            <div className='profile-info__row'>
              <div id='profile-contact-lang-key' className='profile-info__key'>
                {getTranslate('profile.userprofile.interfaceLanguage.label')}
              </div>

              <div
                id='profile-contact-lang-value'
                className='profile-info__value'>
                {fields.interfaceLang.label}
              </div>
            </div>
            <div className='profile-info__row'>
              <div id='profile-contact-lang-key' className='profile-info__key'>
                {getTranslate('profile.userprofile.contactLanguage.label')}
              </div>

              <div
                id='profile-contact-lang-value'
                className='profile-info__value'>
                {fields.contactLang.label}
              </div>
            </div>
            <div className='profile-info__row'>
              <div id='profile-email-key' className='profile-info__key'>
                {getTranslate('profile.userprofile.email.label')}
              </div>

              <div id='profile-email-value' className='profile-info__value'>
                {fields.email}
              </div>
            </div>
            <div className='profile-info__row'>
              <div id='profile-phone-key' className='profile-info__key'>
                {getTranslate('profile.userprofile.contactPhone.label')}
              </div>

              <div
                id='profile-phone-value'
                className={classNames('profile-info__value', {
                  'profile-info__value_phone': fields.contactPhone,
                })}>
                <span className='profile-info__phone-icon'>
                  {fields.contactPhoneCountry && (
                    <ReactCountryFlag
                      countryCode={
                        fields.contactPhoneCountry ||
                        getCountryByPhone(fields.contactPhone)?.code
                      }
                      svg
                    />
                  )}
                </span>
                {fields.contactPhone || '—'}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  // TODO now its turned off
  renderSocialLinks() {
    const { getTranslate } = this.props;
    return (
      <div className='profile-info__block profile-info__block_social-links'>
        <div id='profile-social-key' className='card__title card__title-l'>
          {getTranslate('profile.userprofile.messengers.header')}
        </div>
        {this.renderSocialLinkItem('slack')}
        {this.renderSocialLinkItem('skype')}
        {this.renderSocialLinkItem('telegram')}
      </div>
    );
  }

  renderSocialLinkItem(iconAlias) {
    const {
      onChangeField,
      fields: { social },
      isEditable,
      validationErrors,
    } = this.props;
    const label = iconAlias[0].toUpperCase() + iconAlias.slice(1);
    return (
      <div className='profile-info__social'>
        {isEditable ? (
          <Input
            id={iconAlias}
            placeholder=''
            label={label}
            value={social[iconAlias] || ''}
            error={
              validationErrors?.social && validationErrors.social[iconAlias]
            }
            onChange={(event) => {
              const object: any = {
                ...social,
                [iconAlias]: event.target.value,
              };
              onChangeField('social', object);
            }}
            customClass='profile-info__item'
            prefix={
              <img
                className='profile-info__social-value-img'
                src={socialIcons[iconAlias]}
                alt={iconAlias}
              />
            }
            modern
          />
        ) : (
          <div
            id={`profile-social-${iconAlias}-value`}
            className='profile-info__value profile-info__social-value'>
            <img
              className='profile-info__social-value-img'
              src={socialIcons[iconAlias]}
              alt={iconAlias}
            />
            {social[iconAlias] || '—'}
          </div>
        )}
      </div>
    );
  }

  /*
  TODO: dont forget to cut off it
  getSelectedProjects = () => {
    const { fields } = this.props;
    return fields.projects.reduce<string[]>(
      (result, project) =>
        project.isSelected ? [...result, project.text] : result,
      []
    );
  };*/

  getSelectedProviders = () => {
    const { user : { enabledProviders } } = this.props;
    return enabledProviders.map((provider) => provider.text);
  };

  getSelectedFavoriteCurrency = () => {
    const { fields } = this.props;
    return (
      fields.favoriteCurrencies &&
      fields.favoriteCurrencies.reduce<string[]>(
        (result, item) =>
          item.isSelected && !result.some((dItem) => dItem === item.id)
            ? [...result, item.text]
            : result,
        []
      )
    );
  };

  renderFields = () => {
    const {
      fields,
      onChangeField,
      validationErrors,
      isChargebackMailingAvailable,
      isFinancialReportMailingAvailable,
      isFraudMailingAvailable,
      getTranslate,
    } = this.props;

    return (
      <>
        <CustomSelect
          label='profile.userprofile.timeZone.label'
          options={fields.availableTimezones}
          value={fields.timezone}
          onChange={(value) => onChangeField('timezone', value)}
          customClass='profile-info__item profile-info__timezone'
          id='profile-tz-select'
          modern
        />

        <CustomSelect
          label='profile.userprofile.interfaceLanguage.label'
          options={fields.availableInterfaceLanguages}
          value={fields.interfaceLang}
          onChange={(value) => onChangeField('interfaceLang', value)}
          customClass='profile-info__item'
          id='profile-interface-lang-select'
          modern
        />

        <CustomSelect
          label='profile.userprofile.contactLanguage.label'
          options={fields.availableLanguages}
          value={fields.contactLang}
          onChange={(value) => onChangeField('contactLang', value)}
          customClass='profile-info__item'
          id='contactLang'
          modern
        />

        <Input
          label='profile.userprofile.email.label'
          id='email'
          placeholder=''
          value={fields.email}
          disabled={true}
          onChange={(event) => onChangeField('email', event.target.value)}
          error={getTranslate(validationErrors.email)}
          customClass='profile-info__item'
          modern
        />

        <PhoneInput
          label='profile.userprofile.contactPhone.label'
          id='contactPhone'
          placeholder=''
          value={fields.contactPhone}
          onChange={(event, { dialCountry, value }) =>
            onChangeField(
              'contactPhone',
              `${dialCountry.dial_code}${value}`,
              dialCountry.code
            )
          }
          selectedCountryCode={fields.contactPhoneCountry}
          error={getTranslate(validationErrors.contactPhone)}
          cleaveOptions={{
            phone: true,
          }}
          customClass='profile-info__item'
        />
      </>
    );
  };
}

export default addTranslation(addPermissions(ProfileInfo));
