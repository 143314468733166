import { createAction } from 'helpers/redux';
import _chunk from 'lodash/fp/chunk';
import { v4 as uuid } from 'uuid';

import { closeModal } from 'actions/modal';
import ACTIONS from 'constants/actionTypes';

import {
  createChart as createChartRequest,
  deleteChart,
  editChart,
  getChart,
  getChartFilters,
  getUserCharts,
  moveChart,
} from 'api/analytics';

import { isGraph } from 'components/modal/modalList/graphDetails/components/settingsForm/utils';
import getLocalizedText from 'helpers/getLocalizedText';

import showNotification from 'components/ui/notification/showNotification';

import { ServiceCheckGraphEmpty } from 'components/modal/modalList/graphDetails/services/ServiceCheckGraphEmpty';
import { ServiceSettingsFormHelpers } from 'components/modal/modalList/graphDetails/services/ServiceSettingsFormHelpers';
import { ServiceChartDataFormatter } from 'components/modal/modalList/graphDetails/services/ServiceChartDataFormatter';

import { groupByHiddenAdapter as FraudReportGroupByHiddenAdapter } from 'components/modal/modalList/graphDetails/components/settingsForm/FraudReportFormConfig';
import { groupByHiddenAdapter as ChargebackGroupByHiddenAdapter } from 'components/modal/modalList/graphDetails/components/settingsForm/ChargebackFormConfig';

import {
  ById,
  ByOrder,
  ChargebackDataInner,
  ChartData,
  ChartFilters,
  ChartViewData,
  DeclineReasonsData,
  FraudReportDataInner,
  GraphData,
  IChartDataNormalizerObject,
  IDeclineCodesFormValues,
  IFiltersAdditional,
  IFormValues,
  InOutData,
  IPaymentsFormValues,
  OrderData,
  TopTenFormValues,
} from 'types/Analytics';
import { AdvancedAnalyticsEntityType } from 'components/modal/modalList/graphDetails/components/AdvancedAnalyticsEntityTypes/AdvancedAnalyticsEntityTypes';
import getMessageError from 'helpers/getMessageError';
import {
  preparePaymentsRequest,
  prepareDeclineCodesRequest,
  prepareTopTenRequest,
} from './analytics/formatters/request';

const CHUNK_THRESHOLD = 2;
const NO_DATA_TEXT = 'analytics.mainView.noDataFound.error';
const SUCCESS_SAVED_CHART_TEXT = 'analytics.mainView.chartSaved.text';

enum CHART_TYPES {
  'line' = 'line',
  'pie chart' = 'pie chart',
  'bar' = 'bar',
}

const chartDataFormatterWrapper = (
  chartData: GraphData['data']
): ChartViewData | undefined => {
  if (
    'chart' in chartData &&
    (chartData.chart === AdvancedAnalyticsEntityType.operations ||
      chartData.chart === AdvancedAnalyticsEntityType.payments) &&
    chartData.chartType in CHART_TYPES
  ) {
    const _chartData = chartData as ChartData['data'];
    const serviceFormatter = new ServiceChartDataFormatter(_chartData);

    return serviceFormatter.format();
  } else if (
    'chart' in chartData &&
    chartData.chart === AdvancedAnalyticsEntityType.inout
  ) {
    const _chartData = chartData as InOutData;

    _chartData.chartGroup = AdvancedAnalyticsEntityType.inout;
    _chartData.uuid = uuid();

    return _chartData;
  } else if ('chart' in chartData && chartData.chart === 'pivot') {
    const _chartData = chartData as DeclineReasonsData;

    _chartData.chartGroup = AdvancedAnalyticsEntityType.declineReasons;
    _chartData.uuid = uuid();

    return _chartData;
  } else if (chartData.chart === AdvancedAnalyticsEntityType.topten) {
    const _chartData = chartData as ChartData['data'];

    return new ServiceChartDataFormatter(_chartData).format();
  } else if (chartData.chart === AdvancedAnalyticsEntityType.declineCodes) {
    const _chartData = chartData as ChartData['data'];

    return new ServiceChartDataFormatter(_chartData).format();
  } else if (chartData.chart === AdvancedAnalyticsEntityType.chargeback) {
    if (chartData.chartType === 'simple_table') {
      const _chartData = chartData as ChargebackDataInner;

      _chartData.chartGroup = AdvancedAnalyticsEntityType.chargeback;
      _chartData.uuid = uuid();

      return _chartData;
    }

    const _chartData = chartData as ChartData['data'];

    return new ServiceChartDataFormatter(_chartData).format();
  } else if (chartData.chart === AdvancedAnalyticsEntityType.fraud_report) {
    if (chartData.chartType === 'simple_table') {
      const _chartData = chartData as FraudReportDataInner;

      _chartData.chartGroup = AdvancedAnalyticsEntityType.fraud_report;
      _chartData.uuid = uuid();

      return _chartData;
    }

    const _chartData = chartData as ChartData['data'];

    return new ServiceChartDataFormatter(_chartData).format();
  }

  return undefined;
};

const measureFieldsSerializer = ({
  chartData,
}: {
  chartData: string;
}): IChartDataNormalizerObject | undefined => {
  switch (chartData) {
    case 'count': {
      return {
        chartData: 'count',
        measureField: 'operation_id',
      };
    }
    case 'count_transactions': {
      return {
        chartData: 'count',
        measureField: 'transaction_id',
      };
    }
    case 'amount': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount',
        measureFunction: 'sum',
      };
    }

    case 'sum_channel_amount_in_eur': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_eur',
        measureFunction: 'sum',
      };
    }

    case 'sum_channel_amount_in_usd': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_usd',
        measureFunction: 'sum',
      };
    }

    case 'sum_channel_amount_in_gbp': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_gbp',
        measureFunction: 'sum',
      };
    }

    case 'sum_channel_amount_in_rub': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_rub',
        measureFunction: 'sum',
      };
    }

    case 'avg_channel_amount': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount',
        measureFunction: 'avg',
      };
    }

    case 'avg_channel_amount_in_eur': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_eur',
        measureFunction: 'avg',
      };
    }

    case 'avg_channel_amount_in_usd': {
      return {
        chartData: 'amount',
        measureField: 'channel_amount_in_usd',
        measureFunction: 'avg',
      };
    }

    case 'unique_customers': {
      return {
        chartData: 'count',
        measureField: 'customer_id',
        measureFunction: 'distinct',
      };
    }

    case 'count_chargeback_id': {
      return {
        chartData: 'count',
        measureField: 'chargeback_id',
        measureFunction: 'count',
      };
    }

    case 'sum_amount_in_usd': {
      return {
        chartData: 'amount',
        measureField: 'amount_in_usd',
        measureFunction: 'sum',
      };
    }

    case 'ratio_by_count': {
      return {
        chartData: 'count',
        measureField: 'chargeback_id',
        measureFunction: 'ratio_by_number',
      };
    }

    case 'ratio_by_amount': {
      return {
        chartData: 'amount',
        measureField: 'amount_in_usd',
        measureFunction: 'ratio_by_amount',
      };
    }

    case 'numberOfFraudOperations': {
      return {
        chartData: 'count',
        measureField: 'arn',
        measureFunction: 'count',
      };
    }

    case 'fraudAmountUSD': {
      return {
        chartData: 'amount',
        measureField: 'fraud_amount_in_usd',
        measureFunction: 'sum',
      };
    }

    case 'fraudRatioByNumber': {
      return {
        chartData: 'count',
        measureField: 'arn',
        measureFunction: 'ratio_by_number',
      };
    }

    case 'fraudRatioByAmount': {
      return {
        chartData: 'amount',
        measureField: 'fraud_amount_in_usd',
        measureFunction: 'ratio_by_amount',
      };
    }

    default:
      return undefined;
  }
};

const actionCreator = (id, chartData) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.CHART_SAVED,
        payload: {
          id,
          chartData,
        },
      })
    );
  };
};

const newChartItem = ({
  id,
  chartData,
}: {
  id: string;
  chartData: GraphData['data'];
}) => {
  const _chartData = chartDataFormatterWrapper(chartData);

  return actionCreator(id, _chartData);
};

interface ILoadChartData {
  id?: string;
  chartData?: ChartViewData;
  error?: string;
  order?: number;
}

const loadChartData = ({ id, chartData, error, order }: ILoadChartData) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.CHART_LOADED,
        payload: {
          id,
          chartData,
          error,
          order,
        },
      })
    );
  };
};

const loadSharedWithMeChartData = ({
  id,
  chartData,
  error,
  order,
}: ILoadChartData) => {
  return (dispatch) => {
    dispatch(
      createAction({
        type: ACTIONS.CHART_SHARED_WITH_ME_LOADED,
        payload: {
          id,
          chartData,
          error,
          order,
        },
      })
    );
  };
};

function fetchUserCharts() {
  return async (dispatch) => {
    try {
      const chartsData = await getUserCharts();

      dispatch({
        type: ACTIONS.CHARTS_LOADED,
        payload: {
          chartsData,
        },
      });
    } catch (err) {}
  };
}

function fetchChartItems() {
  return async (dispatch, getStore) => {
    const {
      analytics: {
        gridData: { byOrder },
      },
    }: { analytics: { gridData: { byOrder: ByOrder } } } = getStore();
    const orderList = Object.keys(byOrder);
    const batchedData = _chunk(CHUNK_THRESHOLD, orderList);

    for (const orderBatchItem of batchedData) {
      const promiseList = orderBatchItem.map(async (orderItem) => {
        try {
          const { data } = await getChart({ id: byOrder[orderItem].biChartId });
          const chartData = chartDataFormatterWrapper(data);

          dispatch(
            loadChartData({
              id: data.biChartId,
              chartData,
              order: Number(orderItem),
            })
          );
        } catch (err) {
          const errText = getMessageError(err);

          dispatch(
            loadChartData({
              error: errText,
              order: Number(orderItem),
              id: byOrder[orderItem].biChartId,
            })
          );
        }
      });

      try {
        await Promise.all(promiseList);
      } catch (err) {}
    }
  };
}

function removeChart({ order, id }: { order: number; id: string }) {
  return async (dispatch, getStore) => {
    const {
      analytics: {
        gridData: { byOrder, orderData },
        chartData: { byId },
      },
    }: {
      analytics: {
        gridData: { byOrder: ByOrder; orderData: OrderData };
        chartData: { byId: ById };
      };
    } = getStore();

    dispatch(
      createAction({
        type: ACTIONS.CHART_DELETED,
        payload: {
          order,
          id,
        },
      })
    );

    try {
      await deleteChart({ id });
    } catch (err) {
      dispatch(
        createAction({
          type: ACTIONS.CHART_RESTORE_STATE,
          payload: {
            byId,
            orderData,
            byOrder,
          },
        })
      );
    }
  };
}

function changeOrder({
  oldOrder,
  newOrder,
}: {
  oldOrder: number;
  newOrder: number;
}) {
  return async (dispatch, getStore) => {
    const {
      analytics: {
        gridData: { byOrder, orderData },
        chartData: { byId },
      },
    }: {
      analytics: {
        gridData: { byOrder: ByOrder; orderData: OrderData };
        chartData: { byId: ById };
      };
    } = getStore();

    const movedBiChartId = byOrder[oldOrder]['biChartId'];
    const newOrderData = orderData.filter(
      ({ biChartId }) => biChartId !== movedBiChartId
    );

    newOrderData.splice(newOrder - 1, 0, {
      biChartId: movedBiChartId,
      order: -1,
    });

    let index = 0;

    const resultOrderData = newOrderData.map(({ biChartId }) => ({
      biChartId,
      order: ++index,
    }));

    dispatch(
      createAction({
        type: ACTIONS.CHART_ORDER_CHANGED,
        payload: {
          orderData: resultOrderData,
          byOrder,
        },
      })
    );

    try {
      await moveChart({
        from: movedBiChartId,
        to: byOrder[newOrder]['biChartId'],
      });
    } catch (err) {
      dispatch(
        createAction({
          type: ACTIONS.CHART_RESTORE_STATE,
          payload: {
            orderData,
            byOrder,
            byId,
          },
        })
      );
    }
  };
}

type FiltersNormalizer = IFormValues & IFiltersAdditional;
const filtersNormalizer = ({
  filters,
}: {
  filters: ChartFilters;
}): FiltersNormalizer =>
  Object.entries(filters).reduce((acc, [filterId, value]) => {
    if (
      filterId === 'chartData' &&
      [
        AdvancedAnalyticsEntityType.operations,
        AdvancedAnalyticsEntityType.payments,
        AdvancedAnalyticsEntityType.topten,
        AdvancedAnalyticsEntityType.declineReasons,
        AdvancedAnalyticsEntityType.fraud_report,
        AdvancedAnalyticsEntityType.chargeback,
      ].includes(filters.chart)
    ) {
      acc['chartData'] = ServiceChartDataFormatter.normalizeChartData({
        data: {
          chartData: filters.chartData,
          measureFunction: filters.measureFunction,
          measureField: filters.measureField,
        },
      });

      return acc;
    } else if (
      (filters.chart === AdvancedAnalyticsEntityType.chargeback ||
        filters.chart === AdvancedAnalyticsEntityType.fraud_report) &&
      filterId === 'groupBy'
    ) {
      const hiddenAdapter =
        filters.chart === AdvancedAnalyticsEntityType.chargeback
          ? ChargebackGroupByHiddenAdapter
          : FraudReportGroupByHiddenAdapter;

      if (hiddenAdapter(filters)) {
        acc['groupByMultiSelect'] = value;
      } else {
        acc['groupBy'] = Array.isArray(value) === true ? value[0] : [];
      }

      return acc;
    }

    acc[filterId] = value;

    return acc;
  }, {} as FiltersNormalizer);

const fetchChartFilter = ({ id }) => {
  return async (dispatch) => {
    const { data } = await getChartFilters({ id });
    const normalizedFilters = filtersNormalizer({ filters: data });
    const filterData = { [data.id]: normalizedFilters };

    dispatch(
      createAction({
        type: ACTIONS.CHART_FILTER_LOADED,
        payload: { filterData },
      })
    );
  };
};

// eslint-disable-next-line complexity
const chartDataSerializer = ({
  chartType,
  values,
}: {
  chartType: AdvancedAnalyticsEntityType;
  values: IFormValues;
}) => {
  if (chartType === AdvancedAnalyticsEntityType.operations) {
    if (values.chartData === undefined) {
      throw new Error('Chart Data should not be empty');
    }

    return {
      filters: {
        paymentMethod:
          'paymentMethod' in values ? values.paymentMethod : undefined,
        paymentMethodId:
          'paymentMethodId' in values ? values.paymentMethodId : undefined,
        operationType:
          'operationType' in values ? values.operationType : undefined,
        operationStatus:
          'operationStatus' in values ? values.operationStatus : undefined,
        currency: 'currency' in values ? values.currency : undefined,
        country: 'country' in values ? values.country : undefined,
        issuerBank: 'issuerBank' in values ? values.issuerBank : undefined,
        projectId:
          'projects' in values
            ? values.projects.map((i) => Number(i))
            : undefined,
        issuerCountry:
          'issuerCountry' in values ? values.issuerCountry : undefined,
        operationTypeDms:
          'operationTypeDms' in values ? values.operationTypeDms : undefined,
        lastStepCascade:
          'lastStepCascade' in values ? values.lastStepCascade : undefined,
      },
      chartParameters: {
        chartName: values.chartName,
        chart: 'operations',
        chartType: 'chartType' in values ? values.chartType : undefined,
        groupBy: 'groupBy' in values ? values.groupBy : undefined,
        groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
        period: ServiceSettingsFormHelpers.formatDate(values),
        ...measureFieldsSerializer({ chartData: values.chartData }),
      },
    };
  } else if (chartType === AdvancedAnalyticsEntityType.payments) {
    return preparePaymentsRequest(values as IPaymentsFormValues);
  } else if (chartType === AdvancedAnalyticsEntityType.inout) {
    return {
      filters: {
        projectId:
          'projects' in values && values.projects.map((i) => Number(i)),
        paymentMethod:
          'paymentMethod' in values ? values.paymentMethod : undefined,
        currency: 'currency' in values ? values.currency : undefined,
        type: 'type' in values ? values.type : undefined,
        issuerCountry: 'country' in values ? values.country : undefined,
      },
      chartParameters: {
        chartName: values.chartName,
        chartData: values.chartData,
        chart: 'inout',
        chartType: 'table',
        groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
        period: ServiceSettingsFormHelpers.formatDate(values),
        rows: 'groupByRows' in values ? values.groupByRows : undefined,
        columns: 'groupByColumns' in values ? values.groupByColumns : undefined,
      },
    };
  } else if (chartType === AdvancedAnalyticsEntityType.declineReasons) {
    if (values.chartData === undefined) {
      throw new Error('Chart Data should not be empty');
    }

    return {
      filters: {
        paymentMethod:
          'paymentMethod' in values ? values.paymentMethod : undefined,
        operationType:
          'operationType' in values ? values.operationType : undefined,
        operationStatus:
          'operationStatus' in values ? values.operationStatus : undefined,
        currency: 'currency' in values ? values.currency : undefined,
        issuerCountry:
          'countryByBin' in values ? values.countryByBin : undefined,
        country: 'countryByIp' in values ? values.countryByIp : undefined,
        projectId:
          'projectId' in values
            ? values.projectId.map((i) => Number(i))
            : undefined,
        operationTypeDms:
          'operationTypeDms' in values ? values.operationTypeDms : undefined,
      },
      chartParameters: {
        chartName: values.chartName,
        chart: 'pivot',
        chartType: 'table',
        groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
        period: ServiceSettingsFormHelpers.formatDate(values),
        rows: 'groupByRows' in values ? values.groupByRows : undefined,
        columns: 'groupByColumns' in values ? values.groupByColumns : undefined,
        ...measureFieldsSerializer({ chartData: values.chartData }),
      },
    };
  } else if (chartType === AdvancedAnalyticsEntityType.topten) {
    return prepareTopTenRequest(values as TopTenFormValues);
  } else if (chartType === AdvancedAnalyticsEntityType.declineCodes) {
    return prepareDeclineCodesRequest(values as IDeclineCodesFormValues);
  } else if (chartType === AdvancedAnalyticsEntityType.chargeback) {
    let groupBy;

    if (
      'groupByMultiSelect' in values &&
      Array.isArray(values.groupByMultiSelect) &&
      values.groupByMultiSelect.length !== 0
    ) {
      groupBy = values.groupByMultiSelect;
    } else if ('groupBy' in values && values.groupBy !== '') {
      groupBy = [values.groupBy];
    } else {
      groupBy = [];
    }

    return {
      filters: {
        paymentMethod:
          'paymentMethod' in values ? values.paymentMethod : undefined,
        projectId:
          'projectId' in values
            ? values.projectId.map((i) => Number(i))
            : undefined,
        cardType: 'cardType' in values ? values.cardType : [],
        issuerCountry: 'issuerCountry' in values ? values.issuerCountry : [],
      },
      chartParameters: {
        chartName: values.chartName,
        chart: 'chargeback',
        chartType: 'chartType' in values ? values.chartType : undefined,
        groupBy,
        groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
        period: ServiceSettingsFormHelpers.formatDate(values),
        ...measureFieldsSerializer({ chartData: values.chartData }),
      },
    };
  } else if (chartType === AdvancedAnalyticsEntityType.fraud_report) {
    let groupBy;

    if (
      'groupByMultiSelect' in values &&
      Array.isArray(values.groupByMultiSelect) &&
      values.groupByMultiSelect.length !== 0
    ) {
      groupBy = values.groupByMultiSelect;
    } else if ('groupBy' in values && values.groupBy !== '') {
      groupBy = [values.groupBy];
    } else {
      groupBy = [];
    }

    return {
      filters: {
        projectId:
          'projectId' in values
            ? values.projectId.map((i) => Number(i))
            : undefined,
        cardType: 'cardType' in values ? values.cardType : [],
        issuerCountry: 'issuerCountry' in values ? values.issuerCountry : [],
        brandId: 'brandId' in values ? values.brandId : [],
      },
      chartParameters: {
        chartName: values.chartName,
        chart: AdvancedAnalyticsEntityType.fraud_report,
        chartType: 'chartType' in values ? values.chartType : undefined,
        groupBy,
        groupByDate: 'groupByDate' in values ? values.groupByDate : undefined,
        period: ServiceSettingsFormHelpers.formatDate(values),
        ...measureFieldsSerializer({ chartData: values.chartData }),
      },
    };
  }

  return {
    chartParameters: {},
    filters: {},
  };
};

const editChartItem = ({
  values,
  id,
  chartType,
}: {
  values: IFormValues;
  id: string;
  chartType: AdvancedAnalyticsEntityType;
}) => {
  return async (dispatch) => {
    try {
      const _requestData = chartDataSerializer({ chartType, values });
      const { chartParameters, ...rest } = _requestData;
      const requestData = {
        chartParameters: { ...chartParameters, id },
        ...rest,
      };
      const { data } = await editChart(requestData);
      const isGraphEmpty = ServiceCheckGraphEmpty(data);

      if (isGraphEmpty) {
        showNotification({
          status: 'info',
          content: getLocalizedText(NO_DATA_TEXT),
        });
      } else {
        showNotification({
          status: 'success',
          content: getLocalizedText(SUCCESS_SAVED_CHART_TEXT),
        });
      }

      const chartData = chartDataFormatterWrapper(data);

      dispatch(
        createAction({
          type: ACTIONS.CHART_EDIT,
          payload: {
            id,
            chartData,
          },
        })
      );

      await dispatch(fetchChartFilter({ id }));
      dispatch(closeModal());
    } catch (err) {}
  };
};

const createChart = ({
  graphType,
  values,
}: {
  graphType: AdvancedAnalyticsEntityType;
  values: IFormValues;
}) => {
  return async (dispatch) => {
    const requestData = chartDataSerializer({ chartType: graphType, values });

    try {
      const { data } = await createChartRequest(requestData);
      const isGraphEmpty = ServiceCheckGraphEmpty(data);

      if (isGraphEmpty) {
        showNotification({
          status: 'info',
          content: getLocalizedText(NO_DATA_TEXT),
        });
      } else {
        showNotification({
          status: 'success',
          content: getLocalizedText(SUCCESS_SAVED_CHART_TEXT),
        });
      }

      dispatch(
        newChartItem({
          id: data.biChartId,
          chartData: data,
        })
      );

      await dispatch(fetchChartFilter({ id: data.biChartId }));

      dispatch(closeModal());
    } catch (err) {}
  };
};

const fetchChartFilters = () => {
  return async (dispatch, getStore) => {
    const {
      analytics: {
        chartData: { byId },
      },
    }: { analytics: { chartData: { byId: ById } } } = getStore();

    const idsList = Object.keys(byId);
    const filtersList = await Promise.all(
      idsList.map(async (id) => {
        const filters = await getChartFilters({ id });

        return filtersNormalizer({ filters: filters.data });
      })
    );

    const filtersById = idsList.reduce((acc, id, index) => {
      acc[id] = filtersList[index];

      return acc;
    }, {});

    dispatch(
      createAction({
        type: ACTIONS.CHART_FILTERS_LOADED,
        payload: { filtersById },
      })
    );
  };
};

const reflowAllCharts = () => {
  return (dispatch, getStore) => {
    const {
      analytics: {
        chartData: { byId },
      },
    }: { analytics: { chartData: { byId: ById } } } = getStore();

    const newById = Object.entries(byId).reduce((acc, [id, itemData]) => {
      if (
        'chartType' in itemData &&
        isGraph(itemData.chartType) &&
        'uuid' in itemData
      ) {
        itemData.uuid = uuid();
      }

      acc[id] = itemData;

      return acc;
    }, {});

    dispatch(
      createAction({
        type: ACTIONS.BYID_MUTATED,
        payload: { byId: newById },
      })
    );
  };
};

export {
  loadSharedWithMeChartData,
  CHUNK_THRESHOLD,
  filtersNormalizer,
  fetchChartItems,
  fetchUserCharts,
  removeChart,
  changeOrder,
  editChartItem,
  createChart,
  measureFieldsSerializer,
  fetchChartFilters,
  reflowAllCharts,
  chartDataFormatterWrapper,
  fetchChartFilter,
};
