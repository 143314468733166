import { connectedFilters as connectedFiltersConfig } from 'pages/admin/components/quickFilters/config';

export enum FieldsType {
  text = 'text',
  select = 'select',
  singleSelect = 'singleSelect',
  connectedFilters = 'connectedFilters',
  switch = 'switch',
  phone = 'phone',
}

export const fieldsConfig = (isEditing) => [
  {
    type: 'text',
    labelId: 'myteam.adduser.username',
    name: 'username',
    placeholderId: 'myteam.adduser.username.placeholder',
  },
  {
    type: 'text',
    labelId: 'myteam.adduser.email',
    name: 'email',
    placeholderId: 'myteam.adduser.email.placeholder',
  },
  {
    type: 'phone',
    labelId: 'myteam.adduser.contactPhone',
    placeholderId: 'myteam.adduser.contactPhone.placeholder',
    name: 'contactPhone',
    cleaveOptions: { phone: true },
    disabled: isEditing,
  },
];

export const getAdminFieldsConfig = (isEditing, isPasswordDisabled) => [
  isEditing
    ? {
        type: 'text',
        labelId: 'ID',
        name: 'id',
        disabled: true,
      }
    : {},
  {
    type: 'text',
    labelId: 'myteam.adduser.username',
    name: 'username',
    placeholderId: 'myteam.adduser.username.placeholder',
  },
  {
    type: 'text',
    labelId: 'myteam.adduser.email',
    name: 'email',
    placeholderId: 'myteam.adduser.email.placeholder',
  },
  isEditing
    ? {
        type: 'text',
        labelId: 'Password',
        name: 'password',
        placeholderId: 'Enter password',
        disabled: isPasswordDisabled,
      }
    : {},
  {
    type: 'phone',
    labelId: 'myteam.adduser.contactPhone',
    placeholderId: 'myteam.adduser.contactPhone.placeholder',
    name: 'contactPhone',
    cleaveOptions: { phone: true },
  },
  ...connectedFiltersConfig.map((config) => ({
    ...config,
    type: 'connectedFilters',
  })),
  {
    type: 'singleSelect',
    labelId: 'myteam.edituser.dashboardAdmin.wl.label',
    name: 'wlId',
  },
];
