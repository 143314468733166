import ACTIONS from 'constants/actionTypes';
import { createAction } from 'helpers/redux';
import { updateEntity } from './savedEntities';
import savedEntities from 'constants/savedEntities';
import api from 'api/operations';

export const getOperation = (
    id: string,
    tableName: string,
    onSuccess?: () => void,
    apiRequest?: () => Promise<any>
) => {
    const request = apiRequest || api.getOperationById;
    return (dispatch) => {
        request(id)
            .then((operation) => {
                dispatch(
                    createAction({
                        type: ACTIONS.FETCH_OPERATION_SUCCESS,
                        payload: {
                            operation,
                        },
                    })
                );
                dispatch(
                    updateEntity({
                        entityKey: savedEntities[tableName],
                        id: id.toString(),
                        fields: {
                            data: operation,
                        },
                    })
                );
                onSuccess && onSuccess();
            })
            .catch(({ error }) => {
                dispatch(
                    createAction({
                        type: ACTIONS.FETCH_OPERATION_FAIL,
                        payload: {
                            error,
                        },
                    })
                );
                dispatch(
                    updateEntity({
                        entityKey: savedEntities[tableName],
                        id: id.toString(),
                        fields: {
                            data: {
                                error,
                            },
                        },
                    })
                );
            });
    };
};
