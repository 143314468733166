import React, { PureComponent } from 'react';
import classNames from 'classnames';

import DataBlock from 'components/ui/dataBlock';
import OperationDataMapper from '../OperationDataMapper';

import { AnyObject } from 'types/Common';
import PaymentPageUI from 'types/PaymentPageUI';
import RecurringCardPageUI from 'types/RecurringCardPageUI';
import './operationInfo.scss';

interface Props {
  operation: AnyObject;
  pageUI: PaymentPageUI | RecurringCardPageUI | undefined;
  onChangePageUI: (objectState: AnyObject) => void;
  modern?: boolean;
}

class OperationInfo extends PureComponent<Props> {
  render() {
    const { operation } = this.props;

    if (!operation) return null;

    const {
      operationInfo,
      paymentMethodInfo,
      providerInfo,
      customerInfo,
    } = OperationDataMapper.getOperationInfo(operation);
    const isModern = this.props.modern ? 'modern' : undefined;
    return (
      <div
        className={classNames('payment-operation-info__content-flex-row', {
          'payment-operation-info__content-inner': !this.props.modern,
        })}>
        <div className='grid-column-6'>
          <DataBlock
            title='payments.single.operationInfo.header'
            data={operationInfo}
            theme={isModern}
          />
          <DataBlock
              title='payments.single.providerInfo.header'
              data={providerInfo}
              theme={isModern}
          />
        </div>
        <div className='grid-column-6'>
          <DataBlock
              title='payments.data.paymentMethod.header'
              data={paymentMethodInfo}
              theme={isModern}
          />
          <DataBlock
              title='payments.data.customerInfo.header'
              data={customerInfo}
              theme={isModern}
          />
        </div>
      </div>
    );
  }
}

export default OperationInfo;
