export const inputFields = [
  {
    id: 'userId',
    type: 'number',
    label: 'admin.quickfilters.userId.label',
    placeholder: 'admin.quickfilters.userId.placeholder',
  },
  {
    id: 'email',
    label: 'admin.quickfilters.email.label',
    placeholder: 'admin.quickfilters.email.placeholder',
  },
];

export const userPropertiesFilterItems = [
  {
    valueProp: 'userStatusAdmin',
    filterProp: 'status',
    labelId: 'admin.quickfilters.status.label',
  },
  {
    valueProp: 'roleToSee',
    filterProp: 'role',
    labelId: 'admin.quickfilters.role.label',
  },
];

type ConnectedFiltersType = {
  valueProp: string;
  filterProp: string;
  labelId: string;
  connectedTo: string[];
  hideForQuickFilters?: boolean;
};
export const connectedFilters: ConnectedFiltersType[] = [
];
